import React, { useState, useEffect} from 'react';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';

import { Icon } from 'react-icons-kit';
import {shop} from 'react-icons-kit/ikons/shop';

import { URL_APLICATION } from '../../../services/api';

import { formatToMoney } from '../../../services/utils';

import '../../../assets/style/component/card/request/index.scss';

export const CardRequest = (props) => {

    const [avatar, setAvatar] = useState(null);
    const [showDialog, setShowDialog] = useState(false);

    const getAvatar = () => {
        return avatar ? avatar : <Icon size={'4em'} icon={shop} /> ;
    }

    const getAddress = () => {
        const address = props.enderecos_data.filter(item => ~~item.id === ~~props.esc_responsaveis_enderecos_id)[0];

        if(!address)
            return <></>;

        return <>
            <div>
                {address.endereco}, {address.numero}{address.complemento ? ` - ${address.complemento}` : ''} - {address.bairro} - {address.cidade}
            </div>
            <div>
                {address.referencia ? `${address.referencia}` : ''}
            </div>
        </>
    }

    const showDetails = () => {
        setShowDialog(true);
    }

    useEffect(()=>{

        console.log('$$', props);

        const avatar_image = new Image();
        avatar_image.setAttribute('src', `${URL_APLICATION}${props.empresa_data.detalhe?.icone}`);
        avatar_image.addEventListener('load', ()=>{
            setAvatar(<img style={{width: '100%'}} src={`${URL_APLICATION}${props.empresa_data.detalhe?.icone}`} alt="" />);
        });    
    },[props])

    return (
        <div className="card-request">
            <div className="flag">
                {getAvatar()}
            </div>
            <div className="title">
                {props.empresa_data.nome}
            </div>
            <div className="data">
                <div>
                    <strong>Data</strong>: {new Date(props.emissao).toLocaleDateString()} - {new Date(props.emissao).toLocaleTimeString()}
                </div>
                <div>
                    <strong>Quantidade de produtos</strong>: {props.items_data?.length}
                </div>
                <div>
                    <strong>Valor</strong>: {formatToMoney(props.total)}
                </div>
            </div>
            <div className="buttons">
                <Button onClick={showDetails} icon="pi pi-eye" className="p-button-text"  />
            </div>
            <Dialog header={
                <>Pedido: {props.unique}</>
            } visible={showDialog} style={{ width: '80vw', maxWidth:'900px' }} footer={null} onHide={() => setShowDialog(false)}>
                <div className="p-grid">
                    <div className="p-col-1">
                        {getAvatar()}
                    </div>
                    <div className="p-col-11">
                        <h3>{props.empresa_data.nome}</h3>
                    </div>
                </div>
                <div className="p-grid p-mb-2">
                    <div className="p-col">
                        <strong>Realizado em</strong>: {new Date(props.emissao).toLocaleDateString()} - {new Date(props.emissao).toLocaleTimeString()}
                    </div>
                </div>
                <div style={{fontSize:'0.8em'}}>
                        {
                            props.items_data?.map(item =>
                                    <div className="p-grid">
                                        <div className="p-col-1">{~~item.qtde}</div>
                                        <div className="p-col-9">
                                            {item.descricao}
                                            {item.observacao ? <div>{item.observacao}</div> : <></>}
                                        </div>
                                        <div className="p-col-2">{formatToMoney(item.preco)}</div>
                                    </div>)
                        }
                    
                </div>
                <div className="p-grid p-m-2">
                    <div className="p-col">
                        <div><strong>Entrega</strong>:</div>
                        {getAddress()}
                    </div>
                </div>
                <Divider />
                <div className="p-d-flex p-jc-end p-mt-2">
                    <div><strong>Total</strong>: {formatToMoney(props.total)}</div>
                </div>
            </Dialog>
        </div>
    )
}
