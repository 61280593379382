import React from 'react';

import { Button } from 'primereact/button';

import '../../../assets/style/component/card/credit/index.scss';

export const CardCredit = (props) => {

    const formatCard = (_data) => {
        let cardNumber = _data.split('');

        let temp = [];

        for(let i=0, ii= cardNumber.length; i < ii; i+=4){
            temp.push(`${cardNumber[i]}${cardNumber[i + 1]}${cardNumber[i+2]}${cardNumber[i + 3]}`)
        }
        
        return temp.join(' ');
    }

    return (
        <div className="card-credit">
            <div className="flag">
                {props.bandeira}
            </div>
            <div className="title">
                {props.portador}
            </div>
            <div className="data">
                <div><strong>Número</strong>: {formatCard(props.numerotruncado)}</div>
                <div><strong>Validade</strong>: {props.val_mes}/ {props.val_ano}</div>
            </div>
            <div className="buttons">
                <Button onClick={()=> props.onUpdate(props.id)} icon="pi pi-pencil" className="p-mr-2 p-button-text"  />
                <Button onClick={()=> props.onDelete(props.id)} icon="pi pi-trash"  className="p-button-text p-button-danger" />
            </div>
        </div>
    )
}