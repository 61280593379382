import React, {useState, useEffect} from 'react';

import { Button } from 'primereact/button';

import { ProductImage } from '../image';

import '../../../assets/style/component/card/promotion/index.scss';

export const CardPromotion = (props) => {

    const [image, setImage] = useState('');
    //console.log(props);

    useEffect(()=> {

        setImage(<ProductImage url={props?.icone_pedidoweb} alt={props?.icone_url} />);

    },[props]);


    return(
        <div id={props.cardid} className="card-promotion p-shadow-1">
            <div className="main-frame">
                <div className="area-image">
                    <div className="avatar">
                        {/* <ProductImage url={props?.icone_pedidoweb} alt={props?.icone_url} /> */}
                        {image}
                    </div>
                </div>
                <div className="area-text">
                    <div className="product-name">
                        {props.produto}
                    </div>
                    <div className="product-description">
                        {props?.detalhes}
                    </div>
                    <div className="product-action">
                        <div className="price">
                            R$ {parseFloat(props.preco).toFixed(2).toString().replace('.',',')}
                        </div>
                        <div className="button">
                            <Button onClick={()=> props.onAddCart(props) } icon="pi pi-shopping-cart" className="p-buttom-sm" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}