//export const URL_API = 'https://api.lanchar.com.br/';
export const URL_API = (/development/gi).test(process.env.NODE_ENV) ?  'http://localhost/lanchar/api/' : '/api/';
//export const URL_API = '/api/';
export const URL_APLICATION = 'https://www.lanchar.com.br/';

export const client_data  = {
    id:335,
    name:'Filipinho Delivery',
    phone: '(31) 9 9877-6125',
    avatar: 'image.png',
    address: 'Praça Paulo Pinto Chagas, 100 - Eldorado',
    open:[
        {id:0, open:'20:00', close: '23:59'},
        {id:1, open:'12:00', close: '23:59'},
        {id:2, open:'12:00', close: '23:59'},
        {id:3, open:'12:00', close: '23:59'},
        {id:4, open:'12:00', close: '23:59'},
        {id:5, open:'12:00', close: '23:59'},
        {id:6, open:'20:00', close: '23:59'},
    ],
    delivery:'R$4,00',
    min_buy:'R$15,00',
    categories:['Bebidas', 'Hamburgueres', 'Hot Dogs', 'Promoções'],
    products:[
        {
            id: 1, 
            categories_id: 0,
            image:'',
            name:'Suco',
            description: 'TESTE teste teste teste teste teste teste teste teste teste teste',
            price: 4.00,
            items:[]
        },
        {
            id: 2, 
            categories_id: 0,
            image:'',
            name:'Refrigerante lata',
            description: '',
            price: 5.00,
            items:[]
        },
        {
            id: 3, 
            categories_id: 0,
            image:'',
            name:'Refrigerante normal/sem açúcar 2 litros',
            description: '',
            price: 7.50,
            items:[]
        },
        {
            id: 4, 
            categories_id: 1,
            image:'',
            name:'X-Tudo',
            description: 'TESTE teste teste teste teste teste teste teste teste teste teste',
            price: 24.00,
            items:[]
        },
        {
            id: 5, 
            categories_id: 1,
            image:'',
            name:'Egg bacon',
            description: '',
            price: 15.00,
            items:[]
        },
        {
            id: 6, 
            categories_id: 2,
            image:'',
            name:'Pitbull',
            description: '',
            price: 7.50,
            items:[]
        }
    ]
}