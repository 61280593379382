import React, {useState, useRef, useEffect, useCallback} from 'react';

import { Button } from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';
import { TabMenu } from 'primereact/tabmenu';
import { Dialog } from 'primereact/dialog';
import { ProgressBar } from 'primereact/progressbar';
import { InputNumber } from 'primereact/inputnumber';
import { Carousel } from 'primereact/carousel';
import { Divider } from 'primereact/divider';
import { Toast } from 'primereact/toast';

import { ChartAdd } from '../../../components/chart/add';

import { CardProduct } from '../../../components/card/product';
import { CardPromotion } from '../../../components/card/promotion';


import location from '../../../assets/image/location.png';
import open from '../../../assets/image/open.png';
import delivery from '../../../assets/image/delivery.png';
import money from '../../../assets/image/money.png';

import { Icon } from 'react-icons-kit';
import {facebook2} from 'react-icons-kit/icomoon/facebook2';
import {instagram} from 'react-icons-kit/fa/instagram';
import {shop} from 'react-icons-kit/ikons/shop'

import {  getCategoryProducts, getProducts } from '../../../services/api/company';
import { URL_APLICATION } from '../../../services/api';

import { addItemCart } from '../../../services/shoppingcart';

import { formatToMoney } from '../../../services/utils';

import { registerManifest, registerPWA } from '../../../pwa';

import '../../../assets/style/page/business/index.scss';

export const PageCompany = (props) => {


    const [company, setCompany] = useState(null);
    const [categories, setCategories] = useState([]);
    const [currentCategory, setCurrentCategory] = useState();
    const [content, setContent] = useState(null);
    const [showAdd, setShowAdd] = useState(false);
    const [avatar, setAvatar] = useState(null);
    const [selectedItem, setSelectedItem] = useState('');
    const [selectedItemQuantity, setSelectedItemQuantity] = useState(1);
    const [selectedItemPrice, setSelectedItemPrice] = useState('');
    const [selectedItemAdd, setSelectedItemAdd] = useState(null);

    const [promotionalList, setPromotionalList] = useState([]);


    const times_open = useRef();
    const headerCover = useRef();
    const menutab = useRef();
    const contentArea = useRef();

    const currentItem = useRef();
    const currentTotal = useRef();

    const message = useRef();


    const week_names = ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'];

    const verifyIsOpen = () =>{

        const currentDate = new Date(); 
        const week_day =  currentDate.getDay();
        const current_week =  company.detalhe.funcionamento.filter(item => { 
            const reg = new RegExp(item.dia_semana, 'gi');
            return reg.test(week_names[week_day]);
        });

        let start = false;
        let end = false;

        if(current_week[0]){
            let [hour_s, minutes_s, seconds_s] = current_week[0].inicio.split(':');
            const time_s = (~~hour_s * 3600) + (~~minutes_s * 60) + (~~seconds_s);
            start = ((~~currentDate.getHours() * 3600) + (~~currentDate.getMinutes() * 60) + (~~currentDate.getSeconds())) >= time_s;


            let [hour_e, minutes_e, seconds_e] = current_week[0].fim.split(':');
            const time_e = (~~hour_e * 3600) + (~~minutes_e * 60) + (~~seconds_e);
            end =  ((~~currentDate.getHours() * 3600) + (~~currentDate.getMinutes() * 60) + (~~currentDate.getSeconds())) <= time_e;

            end = end ? end : ( start && (((~~currentDate.getHours() * 3600) + (~~currentDate.getMinutes() * 60) + (~~currentDate.getSeconds())) >= time_e));

        }

        let data = 'Fechado';

        if(start && end){
            data = 'Aberto'
        }

        return data;

    }

    const confirmAddCart = () => {
       const product = content.filter(item => ~~item.id === ~~currentItem.current.product_id)[0];

        let list_combo = [];

        if(product?.combo)
            list_combo = product.combo.filter(item => currentItem.current[`item_${item.id}`] > 0);

        list_combo = list_combo.map(item => {
            const _data = {
                name: item.nome,
                quantity: currentItem.current[`item_${item.id}`]
            };

            return _data;
        });

        let item_chart = {
            id: currentItem.current.product_id,
            name: currentItem.current.name,
            quantity: currentTotal.current,
            price: selectedItemPrice,
            combo: list_combo,
            description: currentItem.current.description
        }

        //console.log(item_chart);
        addItemCart(item_chart, company.id);
        setSelectedItemAdd(item_chart);
        setShowAdd(false);
        message.current.show({severity: 'success', summary: 'Sucesso', detail: <>Item adicionado ao carrinho. <Button onClick={()=> props.history.push(`/${company?.id}/cart`)} style={{width: '100%'}} label="Ver carrinho" className="p-button-text p-sm-button p-button-plain" /> </>});
    }

    const updatePrice = useCallback(() => {

        const _data = currentItem.current;

        if(content && _data){


            let price = parseFloat(_data.price);

            const product = content.filter(item => ~~item.id === ~~_data.product_id)[0];

            if(product?.combo){
                product.combo.map(item => {

                    let temp_total = ~~_data[`item_${item.id}`];
                    if(temp_total > 0){
                        price += temp_total * parseFloat(item.valor_unitario)
                    }

                    return item;
                })
            }

            setSelectedItemPrice(price * currentTotal.current);

        }

    },[content, setSelectedItemPrice, currentItem]);

    const updateChartSelectedItem = (_data = null) => {
        setSelectedItemAdd(_data);
        currentItem.current = _data;
        updatePrice();
        
    }

    const addCart = async (_data) => {
        setSelectedItem(<ChartAdd data={_data} onUpdate={updateChartSelectedItem} />);
        currentTotal.current = 1;
        setSelectedItemQuantity(1);
        //updatePrice();
        setShowAdd(true);
    }

    const getAddress = () => {
        if(!company)
            return '';
        
        return `${company?.detalhe?.endereco}, ${company?.detalhe?.numero} - ${company?.detalhe?.bairro}`;
    }

    const getSocialMedia = () => {

        const _facebook = company?.detalhe?.facebook ? <Icon size={'1.5em'} onClick={()=> window.open(`https://www.facebook.com/${company?.detalhe?.facebook}`)} icon={facebook2} /> : '';
        const _instagram = company?.detalhe?.instagram ? <Icon size={'1.5em'} onClick={()=> window.open(`https://www.instagram.com/${company?.detalhe?.instagram}`)} icon={instagram} /> : '';
        
        return <>
            {_facebook}
            {_instagram}
        </>;
    }

    const getAvatar = () => {
        return avatar ? avatar : <Icon size={'4em'} icon={shop} /> ;
    }

    const alterTab = useCallback((next) => {

        if(currentCategory !== next)
            setCurrentCategory(next);

    }, [currentCategory]);

    const getCategoryName = (_id) => {
        const data = categories.filter(item => ~~item.category_id === ~~_id);
        return data[0]?.label;
    }

    const executeScrollTo = (_local) => {

        const list = menutab.current.querySelector('.content-products').querySelectorAll('[id*="anchor-"]');

        let item;

        for(let i=0; i < list.length; i++){
            const exp = new RegExp(`${_local.label}`,'gi');
            const isvalid = exp.test(list[i].getAttribute('id').replace(/-[0-9]*/gi,''));
            if(isvalid){
                item = list[i];
                break;
            }
        }

        if(item){
            contentArea.current.scrollTop = Math.abs(item.offsetParent.offsetTop) - item.getBoundingClientRect().height - 115;
        }
    }


    const controlScroll = useCallback(() => {
        contentArea.current.addEventListener('scroll', (e)=> {
            //console.log(e.target.scrollTop);
            //console.log('#', menutab.current.getBoundingClientRect());
           // console.log('#', e.target.getBoundingClientRect());

            if(~~menutab.current.getBoundingClientRect().y <= 75){
                menutab.current.querySelector('#menutab').classList.add('fixed');
                menutab.current.classList.add('fixed');
            }else{
                menutab.current.querySelector('#menutab').classList.remove('fixed');
                menutab.current.classList.remove('fixed');
            }

            let current = null;

            const list = menutab.current.querySelector('.content-products').querySelectorAll('[id*="anchor-"]');

            Array.prototype.map.call(list, item => {

                //console.log(item.getAttribute('id'), item.getBoundingClientRect().y ,' - ' ,(window.screen.height))

                if(item.getBoundingClientRect().y <= (e.target.getBoundingClientRect().y + 150)){
                    current = item.getAttribute('id');
                }

                return item;

            });


            if(current){
                
                const data = categories.filter(item => {
                    const exp = new RegExp(`${item.label}`,'gi');
                    const isvalid = exp.test(current.replace(/-[0-9]*/gi,''));
                    return isvalid;
                });


               if(~~currentCategory !== data[0]?.id){
                   // console.log('muda', ~~currentCategory, data[0].id);
                    alterTab(data[0]?.id);
               }
                
            }

        })
    },[alterTab, categories, currentCategory]);

    useEffect(()=> {
        props.onUpdateChart();
    }, [selectedItemAdd, props]);

    useEffect(()=> {

        controlScroll();

    },[categories, controlScroll]);

    useEffect(()=> {
        currentTotal.current = selectedItemQuantity;
        updatePrice();

    },[selectedItemQuantity, updatePrice]);


    useEffect(()=> {

        const getData = async () => {


           // console.log('---', props.company)

            const data_company =  props.company;
            setCompany(data_company);

            registerManifest(data_company);

            registerPWA(data_company.id);

            const cover = new Image();
            cover.setAttribute('src', `${URL_APLICATION}${data_company.detalhe?.capa}`);
            cover.addEventListener('load', ()=>{
                //console.log(cover);
            if(headerCover.current?.style)
                headerCover.current.style.backgroundImage = `url(${URL_APLICATION}${data_company.detalhe?.capa})`;
            });

            const avatar_image = new Image();
            avatar_image.setAttribute('src', `${URL_APLICATION}${data_company.detalhe?.icone}`);
            avatar_image.addEventListener('load', ()=>{
                setAvatar(<img src={`${URL_APLICATION}${data_company.detalhe?.icone}`} alt="" />);
            });
            
            
            let category_list = await getCategoryProducts(props.match.params.client);
            const categories_temp = category_list.data.map((item, id) => ({id: id, category_id: ~~item.id, label: item.categoria}) );
            setCategories(categories_temp);
            setCurrentCategory(categories_temp[0].id);
            
            const products_list = await getProducts(props.match.params.client);
            products_list.data.sort((a, b) => (a.categoria - b.categoria));

            const products_temp = products_list.data.filter(item => {
                const isExist = categories_temp.filter(el => ~~el.category_id === ~~item.categoria && (/n/gi).test(item.promocional));
                return isExist.length > 0;
            });
            
            //console.log(products_temp);

            setContent(products_temp);

            const promotional_temp = products_list.data.filter(item => {
                const isExist = categories_temp.filter(el => (/s/gi).test(item.promocional));
                return isExist.length > 0;
            });

            setPromotionalList(promotional_temp);

            //console.log(promotional_temp);




        }

        getData();

    },[props]);

    


    return (
        <div className="page-business">
            <div ref={contentArea} className="content">
                <header ref={headerCover}>
                    <div className="avatar">
                        {getAvatar()}
                    </div>
                    <div className="name">
                        <div className="p-mb-2">{company ? company?.nome :  <ProgressBar style={{width: '100%', minWidth: '100px', height:'10px'}} mode="indeterminate" />}</div>
                        <div>{company ? company?.detalhe?.telefone :  <ProgressBar style={{width: '100%', minWidth: '100px', height:'10px'}} mode="indeterminate" />}</div>
                    </div>
                </header>
                <div className="resume">
                    <div className="box">
                        <div className="icon">
                            <div className="icon-image">
                                <img src={location} alt="" />
                            </div>
                        </div>
                        <div className="text">
                            {company ? getAddress() :  <ProgressBar style={{width: '100%', minWidth: '100px', height:'10px'}} mode="indeterminate" />}
                        </div>
                    </div>

                    <div className="box">
                        <div className="icon">
                            <div className="icon-image">
                                <img src={open} alt="" />
                            </div>
                        </div>
                        <div className="text">
                            {
                                company ?
                                <>
                                    <Button style={{fontSize:'1em'}} onClick={(e)=> times_open.current.toggle(e) } label={`Estabelecimento ${verifyIsOpen()}`} icon="pi pi-arrow-down" iconPos="right" className="p-button-text p-button-plain" />
                                    <OverlayPanel ref={times_open}>
                                        <ul className="times-open" style={{fontSize:'0.8em'}}>
                                            {
                                                week_names.map((item, id) =>{
                                                    const open = company.detalhe.funcionamento.filter(el => {
                                                        let day_base = el.dia_semana.toLowerCase().replace(/á/gi,'a');
                                                        day_base = day_base.replace(/í/gi,'i');
                                                        day_base = day_base.replace(/ç/gi,'c');

                                                        let day_ref = item.toLowerCase().replace(/á/gi,'a');
                                                        day_ref = day_ref.replace(/í/gi,'i');
                                                        day_ref = day_ref.replace(/ç/gi,'c');

                                                        const reg = new RegExp(day_base, 'gi');
                                                        return  reg.test(day_ref);
                                                    } )[0];
                                                    
                                                    return <li key={`open-list-${id}`}>
                                                        <strong>{item}</strong>: {open ? open.inicio : '-'} ás {open ? open.fim : '-'}
                                                    </li>
                                                })
                                            }
                                        </ul>
                                    </OverlayPanel>
                                </>
                                :
                                <ProgressBar style={{width: '100%', minWidth: '100px', height:'10px'}} mode="indeterminate" />
                            }
                        </div>
                    </div>

                    <div className="box">
                        <div className="icon">
                            <div className="icon-image">
                                <img src={delivery} alt="" />
                            </div>
                        </div>
                        <div className="text">
                            {
                                company ? 
                                <><strong>Taxa de entrega</strong>: {company?.detalhe?.taxaentrega ? formatToMoney(company?.detalhe?.taxaentrega) : formatToMoney(~~company?.detalhe?.taxaentrega)}</>
                                :
                                <ProgressBar style={{width: '100%', minWidth: '100px', height:'10px'}} mode="indeterminate" />
                            }
                        </div>
                    </div>

                    <div className="box">
                        <div className="icon">
                            <div className="icon-image">
                                <img src={money} alt="" />
                            </div>
                        </div>
                        <div className="text">
                            {
                                company ?
                                    <><strong>Pedido mínimo</strong>: {formatToMoney(company?.detalhe?.pedidominimo)}</>
                                :
                                    <ProgressBar style={{width: '100%', minWidth: '100px', height:'10px'}} mode="indeterminate" />
                            }
                        </div>
                    </div>
                </div>
                <section ref={menutab}>
                    <TabMenu id="menutab"  activeIndex={currentCategory} onTabChange={(e)=> { setCurrentCategory(e.value.id); executeScrollTo(e.value); } } model={categories} />
                    <div className="content-products">
                   
                        {

                            categories && content ?
                                categories.map(cat => {
                                    return<div key={`ref-cat-${cat.id}`}>
                                        <Divider style={{width: '100%'}} align="left">
                                            <div id={`anchor-${cat.label}`} className="p-d-inline-flex p-ai-center">
                                                <b>{cat.label}</b>
                                            </div>
                                        </Divider>
                                        <>
                                            {
                                                content.length > 0 ?
                                                    <>
                                                        {content.map(element => {
                                                            if(~~cat.category_id === ~~element.categoria)
                                                                return <CardProduct key={`card-item-${getCategoryName(element.categoria)}-${element.id}`} cardid={`${getCategoryName(element.categoria)}-${element.id}`} onAddCart={addCart} {...element} />
                                                            else
                                                                return '';

                                                        })}
                                                    </>
                                                :
                                                <div className="not-found">Não há itens.</div>
                                            }
                                        </>
                                    </div>
                                })
                            :
                            <div><ProgressBar style={{width: '96%', minWidth: '100px', height:'10px', margin:'0 auto', gridColumn:'1 / span 2'}} mode="indeterminate" /></div>

                        }

                    </div>
                    {
                        promotionalList.length > 0 ?
                        <>
                            <Divider align="left">
                                <div className="p-d-inline-flex p-ai-center">
                                    <i style={{fontSize:'1.5em', color:'var(--yellow-500)'}} className="pi pi-star p-mr-2"></i>
                                    <b>Itens promocionais</b>
                                </div>
                            </Divider>
                            <Carousel responsiveOptions={[
                                {
                                    breakpoint: '6000px',
                                    numVisible: 3,
                                    numScroll: 3
                                },
                                {
                                    breakpoint: '1024px',
                                    numVisible: 3,
                                    numScroll: 3
                                },
                                {
                                    breakpoint: '768px',
                                    numVisible: 2,
                                    numScroll: 2
                                },
                                {
                                    breakpoint: '560px',
                                    numVisible: 1,
                                    numScroll: 1
                                }
                            ]} circular value={promotionalList} itemTemplate={(element)=> 
                                <CardPromotion key={`promo-item-${element.id}`} cardid={`${getCategoryName(element.categoria)}-${element.id}`} onAddCart={addCart} {...element} />
                            }></Carousel>
                        </>
                        :
                        ''
                    }
                    <Dialog header="Adicionar ao carrinho" visible={showAdd} style={{ width: '90vw', maxWidth:'600px' }} footer={()=><>
                        <div className="p-grid add-cart-footer">
                            <div className="p-col-12">
                                <strong>{formatToMoney(selectedItemPrice)}</strong>
                            </div>
                            <div className="p-col-6">
                                <InputNumber style={{width: '100%', maxWidth:'100px'}} inputId="product-quantity" value={selectedItemQuantity} onValueChange={(e) => {setSelectedItemQuantity(e.value);}} mode="decimal" showButtons min={1} max={999} />
                            </div>
                            <div className="p-col-6">
                                <Button onClick={confirmAddCart} style={{width: '100%'}} label="Salvar" icon="pi pi-shopping-cart" className="p-button" />
                            </div>
                        </div>
                    </>} onHide={() => setShowAdd(false)}>
                        {selectedItem}
                    </Dialog>
                </section>
                <footer>
                    <div className="p-grid">
                        <div className="p-col-12 p-md-6">
                            <div className="company p-mb-1">{company ? company?.nome : <ProgressBar style={{width: '100%', minWidth: '100px', height:'10px'}} mode="indeterminate" />}</div>
                            <div className="slogan">{company ? company?.detalhe?.slogan : <ProgressBar style={{width: '100%', minWidth: '100px', height:'10px'}} mode="indeterminate" />}</div>
                            <div className="social-media">
                                {company ? getSocialMedia() : <ProgressBar style={{width: '100%', minWidth: '100px', height:'10px'}} mode="indeterminate" />}
                            </div>
                        </div>
                        <div className="p-col-12 p-md-6 p-mb-2">
                            {
                                company?.detalhe?.latitude && company?.detalhe?.longitude ?
                                    <iframe  width="100%" height="auto" id="gmap_canvas" title="Localização" src={`https://maps.google.com/maps?q=-19.9411965,-44.1269079&z=16&ie=UTF8&iwloc=&output=embed`} frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe>
                                :
                                ''
                            }
                        </div>
                    </div>
                </footer>
            </div>
            <Toast ref={message} position="bottom-center" />
            <nav>
                teste
            </nav>
        </div>
    )
}