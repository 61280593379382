import React, {useRef, useEffect, useMemo} from 'react';

import { ProductImage } from '../../card/image';
import { Divider } from 'primereact/divider';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';

import { useForm, Controller } from 'react-hook-form';


import { formatToMoney } from '../../../services/utils';

import '../../../assets/style/component/chart/add/index.scss';

export const ChartAdd = (props) => {


    const form = useRef();

    const _data = props.data;

    //console.dir(_data);

    let model_chart = useMemo(()=>({
        product_id: _data.id,
        name: _data.produto,
        price: _data.preco,
        description:''
    }),[_data]);

    if(_data.combo)
        _data.combo.map(item => {model_chart[`item_${item.id}`] = 0; return item;})

    //console.log(model_chart);
    
    //let radioGroups = {}

    //const { control, formState: { errors }, handleSubmit, reset } = useForm({ model_chart });
    const { control } = useForm({ model_chart });

    
    const checkUpdate = () => {
        //console.log(model_chart);
        //console.log(radioGroups);
        props.onUpdate(model_chart);
    }

    let element_combo ;

    if( _data.combo){
        let category_combo = _data.combo.map(item => item.nome_combo);
        category_combo = [...new Set(category_combo)];
        category_combo =  category_combo.map(item => ({name: item, itens: _data.combo.filter(el => el.nome_combo === item)}));
        //console.log(category_combo);

        element_combo = <div style={{width:'100%'}}>
            {
                category_combo.map(category_combo => {
                    return <div key={`category-key-${category_combo.name}`}>
                        <Divider align="left">
                            <div className="p-d-inline-flex p-ai-center">
                                <b>{category_combo.name}</b>
                            </div>
                        </Divider>
                        {
                            category_combo.itens.map(it => {
                                return <div key={`product-key-item-${it.id}`} className="p-grid p-ai-center">
                                    <div className="p-col" >
                                        <div className="name">{it.produto}</div>
                                        <div className="price">{formatToMoney(it.valor_unitario)}</div>
                                    </div>
                                    <div className="p-col-fixed" style={{width:'100px'}}>
                                        {
                                            // ~~it.qtde_acrescimos > 1 ?
                                            //     <Controller name={`item_${it.id}`} defaultValue={0} control={control}  render={({ field, fieldState }) => (
                                            //         <InputNumber value={field.value} {...field} onChange={(e) =>{ field.onChange(e.value); model_chart[field.name] = e.value; checkUpdate(); }} inputId={field.name} showButtons step={1} min="0" max={it.qtde_acrescimos}  />
                                            //     )} />
                                            // :
                                            // <>
                                            //     {radioGroups[it.nome_combo] = ''}
                                            //     <Controller name={`item_${it.id}`} control={control} defaultValue={it.qtde_acrescimos} render={({ field, fieldState }) => (
                                            //         <RadioButton value={field.value} name={it.nome_combo} onChange={(e) =>{ resetGroups(it.nome_combo); radioGroups[it.nome_combo] = it.id; field.onChange(e.value); model_chart[field.name] = 1; checkUpdate(); }} checked={~~radioGroups[it.nome_combo] === ~~it.id} />
                                            //     )} />
                                            // </>

                                            
                                        }
                                        <Controller name={`item_${it.id}`} defaultValue={0} control={control}  render={({ field, fieldState }) => (
                                            <InputNumber value={field.value} {...field} onChange={(e) =>{ field.onChange(e.value); model_chart[field.name] = e.value; checkUpdate(); }} inputId={field.name} showButtons step={1} min="0" max={it.qtde_acrescimos}  />
                                        )} />
                                    </div>
                                </div>
                            })
                        }
                    </div>
                })
            }
        </div>
    }

    useEffect(()=>{

        props.onUpdate(model_chart);

    }, [props, model_chart]);
    

    return (
        <div className="chart-add">
            <form ref={form} >
                <div className="p-grid p-mt-2 add-cart-content">
                    <div className="p-col-fixed">
                        <div style={{width:'120px', height:'120px'}} className="photo-image-product">
                            <ProductImage url={_data?.icone_pedidoweb} alt={_data?.icone_url} />
                        </div>
                    </div>
                    <div className="p-col">
                        <strong>{_data?.produto}</strong>
                    </div>
                    <div className="p-col-12 p-mt-2 p-mb-2">
                        {_data?.detalhes}
                    </div>
                    <div className="p-col-12">
                        {element_combo}
                    </div>
                    <div className="p-col-12">
                    <Divider align="left">
                        <div className="p-d-inline-flex p-ai-center">
                            <b>Informações adicionais</b>
                        </div>
                    </Divider>
                        <Controller name='description' control={control}  render={({ field, fieldState }) => (
                            <InputTextarea value={field.value} {...field} onChange={(e) =>{ field.onChange(e.value); model_chart[field.name] = e.target.value; checkUpdate(); }} id={field.name} placeholder="Exemplo retirar item" style={{width: '100%', resize: 'none'}} rows={5} cols={30}  />
                        )} />
                    </div>
                </div>
            </form>
        </div>
    )
}