import {URL_API} from '..';
import { getToken } from '../../auth';


export const createRequest = async (_data) => {

    
    const token = getToken();

    const header = new Headers();
    header.append('Authorization', `Bearer ${token}`);
    header.append('Content-Length',JSON.stringify(_data).length);

    const params = {
        method: 'POST',
        headers: header,
        redirect: 'follow',
        body: JSON.stringify(_data)
    };

   const request = await fetch(`${URL_API}users/request/create`, params);
    let json;

    try{
     json = await request.json();
    }catch(e){
        json = null;
    }

    return json;
}

export const createRequestItem = async (_data) => {

    
    const token = getToken();

    const header = new Headers();
    header.append('Authorization', `Bearer ${token}`);
    header.append('Content-Length',JSON.stringify(_data).length);

    const params = {
        method: 'POST',
        headers: header,
        redirect: 'follow',
        body: JSON.stringify(_data)
    };

   const request = await fetch(`${URL_API}users/request/create/item`, params);
    let json;

    try{
     json = await request.json();
    }catch(e){
        json = null;
    }

    return json;
}