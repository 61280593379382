import React from 'react';
import ReactDOM from 'react-dom';
import MainRouter from './components/navigation/main/';

import { registerPWA } from './pwa';

import './assets/style/index.scss';

ReactDOM.render(<React.StrictMode><MainRouter /></React.StrictMode>,document.getElementById('root'));

window.scrollTo(0,1);

registerPWA();