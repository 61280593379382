export const formatToMoney = (_data) => {
    if(_data === undefined)
        return '';

    return `R$ ${parseFloat(_data).toFixed(2).toString().replace('.',',')}`;
}

export const calculateDistances = (_from = null, _to = null) => {

    if(!_from && !_to)
        return null;

    const PI = Math.PI;

    const lat1Radianos = _from.lat * PI / 180;
    const lng1Radianos = _from.log * PI / 180;
    const lat2Radianos = _to.lat * PI / 180;
    const lng2Radianos = _to.log * PI / 180;
 
    return (Math.acos(Math.cos(lat1Radianos) * Math.cos(lng1Radianos) * Math.cos(lat2Radianos) * Math.cos(lng2Radianos) + Math.cos(lat1Radianos) * Math.sin(lng1Radianos) * Math.cos(lat2Radianos) * Math.sin(lng2Radianos) + Math.sin(lat1Radianos) * Math.sin(lat2Radianos)) * 6371) * 1.0025;
 
}

export const localeData = {
    br:{
        firstDayOfWeek: 1,
        dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
        dayNamesShort: ['DOM', 'SEG', 'TER', 'QUA', 'QUI', 'SEX', 'SAB'],
        dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
        monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
        monthNamesShort: ['JAN', 'FEB', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ'],
        today: 'Hoje',
        clear: 'Limpar'
    }
}

export const cardList = {
    Visa: /^4[0-9]{12}(?:[0-9]{3})/,
    Mastercard : /^5[1-5][0-9]{14}/,
    Diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
    Amex: /^3[47][0-9]{13}/,
    Discover: /^6(?:011|5[0-9]{2})[0-9]{12}/,
    Hipercard: /^(606282\d{10}(\d{3})?)|(3841\d{15})/,
    Elo: /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})/,
    Jcb: /^(?:2131|1800|35\d{3})\d{11}/,       
    Aura: /^(5078\d{2})(\d{2})(\d{11})$/ 
};

export const getCardFlag = (_card) => {
    
    const cardsNames = Object.keys(cardList);

    const card = cardsNames.filter(item => cardList[item].test(_card));

    return card;

}