import React, {useState, useEffect, useCallback, useRef} from 'react';

import { ProgressBar } from 'primereact/progressbar';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';

import { getCart, removeItemCart, clearCart } from '../../../services/shoppingcart';

import { getUserAddress } from '../../../services/api/client';

import { isAuthenticated } from '../../../services/auth';

import { formatToMoney } from '../../../services/utils';

import { createRequest, createRequestItem } from '../../../services/api/request';

import '../../../assets/style/page/users/cart/index.scss';

export const PageUserCart = (props) => {

    console.log(props.company)

    const [isLoad, setIsLoad] = useState(false);
    const [list, setList] = useState(null);
    const [localDelivery, setLocalDelivery] = useState();
    const [listDelivery, setListDelivery] = useState([]);
    const [total, setTotal] = useState(0);
    const [payment, setPayment] = useState();

    const message = useRef();

    const typePayment = [
        {label:'Cartão Lanchar', value:'0'},
        {label:'Débito', value:'1'},
        {label:'Transferência', value:'2'},
        {label:'Recarga', value:'3'},
        {label:'Taxa de Serviço', value:'4'},
        {label:'Dinheiro', value:'5'},
        {label:'Suprimento', value:'6'},
        {label:'Sangria', value:'7'},
        {label:'Cartão de crédito', value:'8'},
    ]

    const removeChartItem = (_item) => {
        removeItemCart(_item, props.company.id);
        getUserData();
        getTotal();
        props.onUpdateRoot();
    }

    const closeRequest = async () => {

        setIsLoad(true);

        const _data = {
            unique: '',
            empresa: '',
            usuario: '',
            esc_responsaveis_enderecos_id:'' ,
            cartao: 1,
            alterado:'APP',
            tipo: '',
            titular: '',
            total:'',
            contador:''
           }

           const time = new Date();
           const unique = `${props.company.id}${time.getDate()}${time.getMonth()}${time.getFullYear()}${time.getHours()}${time.getMinutes()}${time.getSeconds()}`;

           _data.unique = unique;
           _data.empresa = props.company.id;
           _data.esc_responsaveis_enderecos_id = ~~localDelivery;
           _data.tipo = payment;
           _data.total = total;

           const requestID = await createRequest(_data);

           const list_items = getCart().map(item => {
                const _data_items = {
                    pedido:'',
                    cartao: 1,
                    empresa:'',
                    tipo:'',
                    produto:'',
                    descricao:'',
                    preco:'',
                    qtde:'',
                    total:''
                }

                _data_items.pedido = unique;
                _data_items.empresa = props.company.id;
                _data_items.tipo = _data.tipo;
                _data_items.produto = item.id;
                _data_items.descricao = item.description;
                _data_items.preco = item.price / item.quantity;
                _data_items.qtde = item.quantity;
                _data_items.total = item.price;

                return createRequestItem(_data_items);
           });

           const itens = await Promise.all(list_items);

           setIsLoad(false);

           if(requestID && itens){
                message.current.show({severity: 'success', summary: 'Sucesso', detail: 'Pedido criado com sucesso'});
                clearCart();
                props.onUpdateRoot();

                getUserData();

           }

    }

    const getUserData = useCallback(() =>{

        const getData = async () => {
            const data = await getCart();
        
            setList(data ? data : []);
        }

        getData();

    }, [])

    const getUserAddressData = useCallback(() =>{

        const getData = async () => {
            const data = await getUserAddress(props.company.id);
            
            const list = (data.data|| []).map(item => {
                return {
                    label: `${item.endereco}, ${item.numero}${item.complemento ?  '-' + item.complemento : ''} - ${item.bairro} - ${item.cidade}`,
                    value: item.id,
                    price: props.company?.detalhe?.taxaentrega ? props.company?.detalhe?.taxaentrega : ~~props.company?.detalhe?.taxaentrega
                }
            });

            list.unshift({label: 'Retirar pedido', value:'Retirar', price: 0});
            list.unshift({label: 'Entregar na mesa', value:'Mesa', price: 0});
        
            setListDelivery(list);
        }

        getData();

    }, [props])

    const getTotal =  useCallback(() => {

       const _total = getCart().reduce((_c, _n) => (_c + _n.price), 0);
       const delivery = listDelivery.filter(item => item.value.toString() === localDelivery)[0]?.price;
       setTotal(_total + parseFloat(delivery || 0));
    },[listDelivery, localDelivery]);


    useEffect(()=>{

        getTotal();

    }, [localDelivery, getTotal])

    useEffect(()=> {

        getUserData();
        getUserAddressData();

    }, [getUserData, getUserAddressData])

    return (
        <div className="page-user-cart">
            <div className="main-frame">
                <h3>
                    Carrinho de compras
                </h3>
                <div className="p-flex p-mb-3">
                    <div className="p-col-12" style={{textAlign:'right'}}>
                        <Button onClick={()=>{ 
                            props.history.push(`/${props.company.id}`);
                        }} icon="pi pi-plus" className="p-mr-2 p-button-text" label="Adicionar novo item"  />
                    </div>
                </div>
                {
                    list ?
                        <div key={`address-list-area`}>
                            {
                                list.length > 0 ?
                                    <div key={`address-list`}>
                                        {list.map((item, id) => <div key={`root-chart-list-${item.id}`} className="p-grid p-ai-center" style={{width:'98%'}}>
                                    <div className="p-col-fixed" style={{width:'40px'}}><span className="p-m-1" style={{height: '30px', width: '30px', display:'flex', borderRadius:'50%', padding:'0', alignItems:'center', justifyContent:'center', backgroundColor:'var(--green-100)', fontSize:'0.75em'}} >{id + 1}</span></div>
                                    <div className="p-col" style={{fontSize:'0.8em',  overflow: 'hidden', textOverflow:'ellipsis', whiteSpace: 'nowrap'}}>{item.quantity} X {item.name}</div>
                                    <div className="p-col-fixed" style={{fontSize:'0.8em', textAlign:'right', width:'90px'}}>{formatToMoney(item.price)}</div>
                                    <div className="p-col-fixed" style={{width:'40px'}}>
                                        <Button onClick={()=> removeChartItem(id) } icon="pi pi-trash" className="p-button-rounded p-button-text p-button-danger" />
                                    </div>
                                </div> )}
                                <Divider />
                                <div className="p-gird">
                                    <div className="p-col-12">
                                        <h3>
                                            Local de entrega
                                        </h3>
                                    </div>
                                    <div className="p-col-12">
                                        <div className="p-grid">
                                            <div className="p-col-9">
                                            <Dropdown style={{width: '100%'}} value={localDelivery} options={listDelivery} onChange={(e)=> setLocalDelivery(e.value)}  />
                                            </div>
                                            <div className="p-col-3">
                                                <Button onClick={()=>{ 
                                                props.history.push(`/${props.company.id}/user/address`);
                                            }} className="p-button-text" label="Adicionar novo endereço"  />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-gird">
                                    <div className="p-col-12">
                                        <h3>
                                           Forma pagamento
                                        </h3>
                                    </div>
                                    <div className="p-col-12">
                                        <div className="p-grid">
                                            <div className="p-col-9">
                                            <Dropdown style={{width: '100%'}} value={payment} options={typePayment} onChange={(e)=> setPayment(e.value)}  />
                                            </div>
                                            <div className="p-col-3">
                                                <Button onClick={()=>{ 
                                                props.history.push(`/${props.company.id}/user/cards`);
                                            }} className="p-button-text" label="Adicionar novo cartão"  />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Divider />
                                <div className="p-d-flex p-jc-end p-m-2">
                                      Sub total: {
                                            formatToMoney(getCart().reduce((_c, _n) => (_c + _n.price), 0))
                                        }
                                </div>
                                <div className="p-d-flex p-jc-end p-m-2">
                                      Frete: {
                                            formatToMoney(localDelivery ? listDelivery.filter(item => item.value === localDelivery)[0]?.price : ~~listDelivery.filter(item => item.value === localDelivery)[0]?.price)
                                        }
                                </div>
                                <div className="p-d-flex p-jc-end p-m-2">
                                    <strong>
                                      Total: {
                                            formatToMoney(total)
                                        }
                                    </strong>
                                </div>

                                <div className="p-d-flex p-jc-center p-m-2">
                                    {
                                        isAuthenticated() ?
                                            <>
                                                <Button loading={isLoad} onClick={closeRequest} style={{fontSize:'0.8em', width:'100px' }} label="Finalizar"  className=" p-button-danger" />
                                            </>
                                        :
                                            <>
                                                <Button onClick={()=> props.history.push(`/${props.company.id}/user/login`)} style={{fontSize:'0.8em', width:'200px'}} label="Efetuar Login"  className=" p-button-danger p-m-2" />
                                                <Button onClick={()=> props.history.push(`/${props.company.id}/user/sigup`)} style={{fontSize:'0.8em', width:'200px'}} label="Efetuar Cadastro"  className=" p-button-warning p-m-2" />
                                            </>
                                    
                                    }
                                </div>

                                
                                </div>
                                :
                                    <p>
                                        Não há endereços cadastrados.
                                    </p>
                            }
                        </div>
                    :
                        <ProgressBar style={{width: '100%', minWidth: '100px', height:'10px'}} mode="indeterminate" />
                }
            </div>
            <Toast ref={message} position="bottom-center" />
        </div>
    )
}