import React, {useState, useEffect, useCallback, useRef} from 'react';

import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Toast } from 'primereact/toast';

import { useForm, Controller } from 'react-hook-form';

//import { getUserAddress } from '../../../../services/api/client';

import { userCreateAddress } from '../../../../services/api/client';

import '../../../../assets/style/page/users/address/index.scss';

export const PageUserAddressCreate = (props) => {

   // const [list, setList] = useState(null);

    const [addressData, setAddressData] = useState(null);
    const [isLoad, setIsLoad] = useState(false);

    const defaultValues = {
        titulo:'',
        cep: '',
        endereco:'',
        numero:'',
        complemento:'',
        bairro:'',
        cidade:'',
        referencia:''
    }

    const formFrame = useForm({ defaultValues });
    const control = formFrame.control;
    const errors = formFrame.formState;
    const handleSubmit = formFrame.handleSubmit;
    const reset = formFrame.reset;

    const message = useRef();

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };

    const getAddressData = async (_cep = null) => {

        if(_cep){

            if(_cep.replace(/\D/gi, '').length === 8){

                setAddressData(null);

                const data = await fetch(`https://viacep.com.br/ws/${_cep.replace(/\D/gi, '')}/json/`);
                const address = await data.json();

                setAddressData(address);

                formFrame.setValue('endereco', address.logradouro);
                formFrame.setValue('bairro', address.bairro);
                formFrame.setValue('cidade', address.localidade);
                formFrame.setValue('estado', address.uf);
            }
        }


    }

    const onSave = async (_data) => {
        console.log("--", _data);
        setIsLoad(true);

        const addressID = await userCreateAddress(_data);

        setIsLoad(false);

        if(addressID){
            message.current.show({severity: 'success', summary: 'Sucesso', detail: 'Endereço criado com sucesso'});
            reset();
        }
    }

    const getUserData = useCallback(() =>{

        const getData = async () => {
           // const data = await getUserAddress(props.company?.id);
            //setList(data.data);
        }

        getData();

    }, [])

    useEffect(()=> {

        getUserData();

    }, [getUserData])

    return (
        <div className="page-user-address">
            <div className="main-frame">
                <h3>
                    Adicionar endereço
                </h3>
                <form className="form-address" onSubmit={handleSubmit(onSave)} >
                    <div className="p-grid p-mt-2">
                        <div className="p-col-12 p-md-6">
                            <div className="p-field">
                                <div className="p-float-label">
                                        <Controller name="titulo" control={control} rules={{ required: 'Campo obrigatório.', maxLength: 100 }} render={({ field, fieldState }) => (
                                            <InputText id={field.name} {...field} className={{ 'p-invalid': fieldState.invalid }} />
                                        )} />
                                    <label htmlFor="titulo" className={{ 'p-error': !!errors.titulo }}>Nome *</label>
                                </div>
                                {getFormErrorMessage('titulo')}
                            </div>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <div className="p-field">
                                <div className="p-float-label p-input-icon-right " style={{width:'100%'}}>
                                        <Controller name="cep" control={control}  rules={{ required:'Campo obrigatório.',  maxLength: 10 }} render={({ field, fieldState }) => (
                                                <>
                                                { field.value.replace(/\D/gi,'').length === 8 && !addressData ? <i className="pi pi-spin pi-spinner" /> : '' }
                                                    <InputText type="number" mask="99.999.999"  maxLength="100" id={field.name} {...field} className={{ 'p-invalid': fieldState.invalid }} onChange = {(e)=>{ field.onChange(e.target.value); getAddressData(e.target.value)}}  />
                                                </>

                                        )} />
                                    <label htmlFor="cep" className={{ 'p-error': !!errors.cep }}>CEP *</label>
                                </div>
                                {getFormErrorMessage('cep')}
                            </div>
                        </div>
                    </div>

                    <div className="p-grid p-mt-2">
                        <div className="p-col-12">
                                <div className="p-field">
                                    <div className="p-float-label">
                                            <Controller name="endereco" control={control}  rules={{ required:'Campo obrigatório.'}}  render={({ field, fieldState }) => (
                                                <InputText disabled={!addressData} maxLength="100" id={field.name} {...field} className={{ 'p-invalid': fieldState.invalid }} />
                                            )} />
                                        <label htmlFor="endereco" className={{ 'p-error': !!errors.endereco }}>Endereço *</label>
                                    </div>
                                    {getFormErrorMessage('endereco')}
                                </div>
                            </div>
                            
                        </div>

                        <div className="p-grid p-mt-2">
                            <div className="p-col-12 p-md-3 ">
                                <div className="p-field">
                                    <div className="p-float-label">
                                            <Controller name="numero" control={control} rules={{ required:'Campo obrigatório.', maxLength: 50 }} render={({ field, fieldState }) => (
                                                <InputText id={field.name} {...field} className={{ 'p-invalid': fieldState.invalid }} />
                                            )} />
                                        <label htmlFor="numero" className={{ 'p-error': !!errors.numero }}>Número *</label>
                                    </div>
                                    {getFormErrorMessage('numero')}
                                </div>
                            </div>
                            <div className="p-col-12 p-md-3">
                                <div className="p-field">
                                    <div className="p-float-label">
                                            <Controller name="complemento" control={control} rules={{ maxLength: 50 }} render={({ field, fieldState }) => (
                                                <InputText id={field.name} {...field} className={{ 'p-invalid': fieldState.invalid }} />
                                            )} />
                                        <label htmlFor="complemento" className={{ 'p-error': !!errors.complemento }}>Complemento</label>
                                    </div>
                                    {getFormErrorMessage('complemento')}
                                </div>
                            </div>
                            <div className="p-col-12 p-md-2">
                                <div className="p-field">
                                    <div className="p-float-label">
                                            <Controller name="bairro" control={control} rules={{ required:'Campo obrigatório.'}}  render={({ field, fieldState }) => (
                                                <InputText disabled={!addressData} maxLength="100" id={field.name} {...field} className={{ 'p-invalid': fieldState.invalid }} />
                                            )} />
                                        <label htmlFor="bairro" className={{ 'p-error': !!errors.bairro }}>Bairro *</label>
                                    </div>
                                    {getFormErrorMessage('bairro')}
                                </div>
                            </div>
                            <div className="p-col-12 p-md-2">
                                <div className="p-field">
                                    <div className="p-float-label">
                                            <Controller name="cidade" control={control} rules={{ required:'Campo obrigatório.'}}  render={({ field, fieldState }) => (
                                                <InputText disabled={!addressData} maxLength="100" id={field.name} {...field} className={{ 'p-invalid': fieldState.invalid }} />
                                            )} />
                                        <label htmlFor="cidade" className={{ 'p-error': !!errors.cidade }}>Cidade *</label>
                                    </div>
                                    {getFormErrorMessage('cidade')}
                                </div>
                            </div>
                            <div className="p-col-12 p-md-2">
                                <div className="p-field">
                                    <div className="p-float-label">
                                            <Controller name="estado" control={control} rules={{ required:'Campo obrigatório.'}} render={({ field, fieldState }) => (
                                                <InputText disabled={!addressData} maxLength="100" id={field.name} {...field} className={{ 'p-invalid': fieldState.invalid }} />
                                            )} />
                                        <label htmlFor="estado" className={{ 'p-error': !!errors.estado }}>Estado *</label>
                                    </div>
                                    {getFormErrorMessage('estado')}
                                </div>
                            </div>
                        </div>

                        <div className="p-grid p-mt-2">
                        <div className="p-col-12">
                                <div className="p-field">
                                    <div className="p-float-label">
                                            <Controller name="referencia" control={control}  render={({ field, fieldState }) => (
                                                <InputTextarea style={{width:'100%'}} autoResize={true} id={field.name} {...field} className={{ 'p-invalid': fieldState.invalid }} />
                                            )} />
                                        <label htmlFor="referncia" className={{ 'p-error': !!errors.cpf }}>Ponto de referência</label>
                                    </div>
                                    {getFormErrorMessage('referencia')}
                                </div>
                            </div>
                            
                        </div>

                        <div className="p-grid p-mt-2 p-mb-4">
                            <div className="p-col-12" style={{textAlign:'right'}}>
                                <Button onClick={()=> props.history.push(`/${props.company.id}/user/address`)} style={{width:'100px'}} className="p-mr-2  p-button-danger" label="Cancelar"  />
                                <Button loading={isLoad} style={{width:'100px'}} type="submit" label="Cadastro" />
                            </div>
                        </div>       
                 </form>
            </div>
            <Toast ref={message} position="bottom-center" />
        </div>
    )
}