import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import {isAuthenticated} from '../../../services/auth';

const RoutePritvate = ({component: Component, ...rest}) => {

    console.log('#', {...rest}?.company);

    return (
        <Route 
            {...rest}
            render={ props =>
                isAuthenticated() ?
                    (<Component {...props} />)
                :
                     (<Redirect to={{pathname:`/${{...rest}?.company.id}`, state:{from:props.location}}} />)
            }
        />
    )
}

export default RoutePritvate;