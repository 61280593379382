export const TOKEN_SHOPPING_CART = '@TOKEN_SHOPPING_CART_PROD';

const isCartValid = () => {
    let isValid = false;
    const data = window.localStorage.getItem(TOKEN_SHOPPING_CART);
    let cart = null;
    try{
        cart = atob(data);
        cart = JSON.parse(cart);
    }catch(e){}

    if(cart){
        const current = new Date().getTime();
       // console.log(current , cart);
        isValid = current <= cart.expire;
    }

  //  console.log('cart', isValid);

    return isValid;


}

export const addItemCart = (_data, _company = null) => {
    const temp = getCart();
    temp.push(_data);
    saveCart(temp, _company);
}

export const removeItemCart = (_id, _company = null) => {
    const temp = getCart();
    const newList = temp.filter((item, id) => ~~id !== ~~_id);
    saveCart(newList , _company);
}

export const saveCart = (_data, _company = null )=> { 


    let cart = {};

    if(!isCartValid()){
        clearCart();
    }

    cart = {
        expire : 0,
        company: _company,
        list : _data

    }

    const date_expire = new Date();
    date_expire.setMinutes(date_expire.getMinutes() + 30);
    cart.expire = date_expire.getTime();

    cart = btoa(JSON.stringify(cart));

    window.localStorage.setItem(TOKEN_SHOPPING_CART, cart);
};

export const clearCart = () => { window.localStorage.removeItem(TOKEN_SHOPPING_CART)};

export const getCart = () => {

    if(!isCartValid()){
        clearCart();
        return [];
    }

    const data = window.localStorage.getItem(TOKEN_SHOPPING_CART);

    let cart = null;
    
    if(data){

        try{
            cart = atob(data);
            cart = JSON.parse(cart);
        }catch(e){}
    }

    if(cart){
        return cart.list;
    }
    
    return [];

}