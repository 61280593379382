import React from 'react';
import {BrowserRouter, Switch, Route } from 'react-router-dom';

import { PageCompany } from '../../../pages/business/company';

import { PageUserLogin } from '../../../pages/users/login';
import { PageUserSigup } from '../../../pages/users/sigup';
import { PageUserProfile } from '../../../pages/users/profile';
import { PageUserAddress } from '../../../pages/users/address';
import { PageUserAddressCreate } from '../../../pages/users/address/create';
import { PageUserAddressEdit } from '../../../pages/users/address/edit';
import { PageUserCards } from '../../../pages/users/cards';
import { PageUserCardCreate } from '../../../pages/users/cards/create';
import { PageUserCardEdit } from '../../../pages/users/cards/edit';
import { PageUserRequests } from '../../../pages/users/requests';

import { PageUserCart } from '../../../pages/users/cart';

import RoutePritvate from '../private';

const PagesRouter = (props) => {


    return (
        <BrowserRouter key={props.currentpage}>
            <Switch>
                <Route exact path="/:client" component={(p)=> <PageCompany company={props.company} onUpdateChart={props.onUpdateRoot} {...p} /> } />
                <Route path="/:client/user/login" component={(p)=> <PageUserLogin company={props.company} onUpdateRoot={props.onUpdateRoot} {...p} />} />
                <Route path="/:client/user/sigup" component={(p)=> <PageUserSigup company={props.company} onUpdateRoot={props.onUpdateRoot} {...p} />} />
                <Route path="/:client/cart" component={(p)=> <PageUserCart company={props.company} onUpdateRoot={props.onUpdateRoot} {...p} />} />
                
                <RoutePritvate exact path="/:client/user/profile" company={props.company}  component={(p)=><PageUserProfile company={props.company} onUpdateRoot={props.onUpdateRoot} {...p} />} />
                <RoutePritvate exact path="/:client/user/address" company={props.company}  component={(p)=><PageUserAddress company={props.company} onUpdateRoot={props.onUpdateRoot} {...p} />} />
                <RoutePritvate exact path="/:client/user/address/new" company={props.company}  component={(p)=><PageUserAddressCreate company={props.company} onUpdateRoot={props.onUpdateRoot} {...p} />} />
                <RoutePritvate exact path="/:client/user/address/edit/:address" company={props.company}  component={(p)=><PageUserAddressEdit company={props.company} onUpdateRoot={props.onUpdateRoot} {...p} />} />

                <RoutePritvate exact path="/:client/user/cards" company={props.company}  component={(p)=><PageUserCards company={props.company} onUpdateRoot={props.onUpdateRoot} {...p} />} />
                <RoutePritvate exact path="/:client/user/card/new" company={props.company}  component={(p)=><PageUserCardCreate company={props.company} onUpdateRoot={props.onUpdateRoot} {...p} />} />
                <RoutePritvate exact path="/:client/user/card/edit/:card" company={props.company}  component={(p)=><PageUserCardEdit company={props.company} onUpdateRoot={props.onUpdateRoot} {...p} />} />

                <RoutePritvate exact path="/:client/user/requests" company={props.company}  component={(p)=><PageUserRequests company={props.company} onUpdateRoot={props.onUpdateRoot} {...p} />} />

                <Route path="/:client/user/:page?" component={(p)=><div>{p.location.pathname}</div>} />
                <Route path="*" component={()=><div>404</div>} />
            </Switch>
        </BrowserRouter>
    )

}

export default PagesRouter;