import React, {useState, useRef, useEffect, forwardRef, useImperativeHandle, useCallback } from 'react';

import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Menu } from 'primereact/menu';
import { Divider } from 'primereact/divider';

import { getUserProfile } from '../../../../services/api/client';
import { getCart, removeItemCart } from '../../../../services/shoppingcart';
import { formatToMoney, calculateDistances } from '../../../../services/utils';

import {isAuthenticated, logout} from '../../../../services/auth';

export const PageBusinessHeader = forwardRef((props, ref) => {

    const company = props.company;

    const [showMenu, setShowMenu] = useState(false);

    const [userData, setUserData] = useState();
    const [currentLocation, setCurrentLocation] = useState(null);
    const [distance, setDistance] = useState(null);
    const [chartID, setChartID] = useState(null);

    const contentCart = useRef();
    const contentLocation = useRef();
    const currentLocationCords = useRef();

    const items = [
        {
            label: `Início`,
            icon:'pi pi-home',
            command:()=> props.history.push(`/${company.id}`)
        },
        {
            label: `Carrinho de compras`,
            icon:'pi pi-shopping-cart',
            command:()=> props.history.push(`/${company.id}/cart`)
        }
    ];

   /* const menuCompany = [
        {
            label: `Loja ${company?.nome}`,
            items:[
                {
                    label:'Informações',
                    icon:'pi pi-id-card',
                    command:()=> props.history.push(`/${company.id}/info`)
                }
            ]
        }
    ];*/

    //items.push(...menuCompany);

    const menuUser = [
        {
            label: `Usuário`,
            items:[]
        }
    ]

    if (isAuthenticated()){
        const menu_user_login = [
            {
                label: 'Perfil',
                icon:'pi pi-user',
                command:()=> props.history.push(`/${company.id}/user/profile`)
            },
            {
                label: 'Endereços',
                icon:'pi pi-map-marker',
                command:()=> props.history.push(`/${company.id}/user/address`)
            },
            {
                label: 'Pedidos',
                icon:'pi pi-list',
                command:()=> props.history.push(`/${company.id}/user/requests`)
            },
            {
                label: 'Cartões',
                icon:'pi pi-credit-card',
                command:()=> props.history.push(`/${company.id}/user/cards`)
            }
            
        ];
        menuUser[0].items.push(...menu_user_login);
    }else{
        menuUser[0].items.push({
            label: 'Login',
            icon:'pi pi-user',
            command:()=> props.history.push(`/${company.id}/user/login`)
        });
        menuUser[0].items.push({
            label: 'Cadastro',
            icon:'pi pi-user-plus',
            command:()=> props.history.push(`/${company.id}/user/sigup`)
        });
    }

    items.push(...menuUser);

    if (isAuthenticated()){
        items.push({separator:true});
        items.push( {
            label: `Logout`,
            icon:'pi pi-power-off',
            command:()=>{logout(); updateCart(); props.history.push(`/${company.id}`);}
        })
    }

    useImperativeHandle(ref, () => ({
        updateHeader(){
            updateCart();
            getUserData();
        },
        closeMenu(){
            setShowMenu(false);
        }

    }))

    const getDistance = () => {
        let data = null

        if(currentLocationCords.current && company)
           data = calculateDistances({lat: currentLocationCords.current.latitude,log: currentLocationCords.current.longitude}, {lat: company?.detalhe?.latitude, log: company?.detalhe?.longitude});

        setDistance(`${parseFloat(data).toFixed(2)} KM`);
    }

    const removeChartItem = (_item) => {
        removeItemCart(_item, company.id);
        setChartID(new Date().getTime());

    }

    const updateCart = () =>{
        //setSelectedItemAdd(getCart());
        setChartID(new Date().getTime());
    }

    const contentLeft = ()=> {
        return <>
            <Button onClick={()=>setShowMenu(true) } icon="pi pi-bars" className="p-button-rounded p-button-text p-button-plain" />
        </>;
    }

    const contentRight = ()=> {
        return <>
            {
                isAuthenticated() ?
                    <>
                        <Button style={{fontSize:'0.8em'}} label={userData?.nome.split(' ')[0]} icon="pi pi-user" iconPos="left" className="p-button-text p-button-plain" />
                    </>
                :
                <>
                    <Button onClick={()=>{props.history.push(`/${company?.id}/user/login`)}} label="Login" className="p-button-text p-button" /> 
                    <Button onClick={()=>{props.history.push(`/${company?.id}/user/sigup`)}} label="Cadastrar" className="p-button-text p-button" />
                </>
            
            }
            <Button onClick={(e)=> {contentCart.current.toggle(e); setChartID(new Date().getTime()); }} icon="pi pi-shopping-cart" className={`p-button-rounded ${  getCart().length > 0 ? 'anim-add-cart' : 'p-button-text' } p-button-danger p-mr-1 p-overlay-badge`} />
            <OverlayPanel ref={contentCart}>
                {
                    getCart().length > 0 ?
                        <div style={{minWidth:'290px', width:'90vw', maxWidth:'500px'}}  className="chart-open" id={`root-chart-add-list${chartID}`}>
                            <Divider align="left" className="p-mb-4">
                                <div className="p-d-inline-flex p-ai-center">
                                    <b>Itens adicionados</b>
                                </div>
                            </Divider>
                            <div style={{maxHeight:'calc(100vh - 250px)', height:'100%', overflow:'auto', overflowX:'hidden'}}>
                                {
                                    getCart().map((item, id) =>
                                        <div key={`root-chart-list-${item.id}`} className="p-grid p-ai-center" style={{width:'98%'}}>
                                            <div className="p-col-fixed" style={{width:'40px'}}><span className="p-m-1" style={{height: '30px', width: '30px', display:'flex', borderRadius:'50%', padding:'0', alignItems:'center', justifyContent:'center', backgroundColor:'var(--green-100)', fontSize:'0.75em'}} >{id + 1}</span></div>
                                            <div className="p-col" style={{fontSize:'0.8em',  overflow: 'hidden', textOverflow:'ellipsis', whiteSpace: 'nowrap'}}>{item.quantity} X {item.name}</div>
                                            <div className="p-col-fixed" style={{fontSize:'0.8em', textAlign:'right', width:'90px'}}>{formatToMoney(item.price)}</div>
                                            <div className="p-col-fixed" style={{width:'40px'}}>
                                                <Button onClick={()=> removeChartItem(id) } icon="pi pi-trash" className="p-button-rounded p-button-text p-button-danger" />
                                            </div>
                                        </div>)
                                }
                            </div>
                            <Divider />
                            <div className="p-grid p-mb-2 p-mt-2">
                                <div style={{textAlign: 'right'}} className="p-col-12">
                                    <strong>
                                        {
                                            formatToMoney(getCart().reduce((_c, _n) => (_c + _n.price), 0))
                                        }
                                    </strong>
                                </div>
                            </div>
                            <div className="p-grid">
                                <div className="p-col-12" style={{textAlign: 'center'}}>
                                    <Button onClick={()=>{props.history.push(`/${company?.id}/cart`)}} label="Finalizar" className="p-button p-button-danger" />
                                </div>
                            </div>
                        </div>
                        :
                        <p style={{minWidth:'280px'}}>Não há itens</p>

                }
            </OverlayPanel>
            <Button onClick={(e)=> {contentLocation.current.toggle(e); getDistance(); }} icon="pi pi-map-marker" className="p-button-rounded p-button-text p-button-warning" />
            <OverlayPanel ref={contentLocation}>
                {
                    currentLocation ?
                        <>
                            <p>{currentLocation}</p>
                            <Divider style={{width: '100%'}} align="left">
                                <div className="p-d-inline-flex p-ai-center">
                                    <i className="pi pi-map p-mr-2"></i>
                                    <b>Distância do estabelecimento</b>
                                </div>
                            </Divider>
                            {
                                distance ?
                                    <p>{distance}</p>
                                :
                                    <p style={{minWidth:'200px'}}>Localização não determinada</p>
                            }
                            
                        </>
                    :
                        <p style={{minWidth:'200px'}}>Localização não determinada</p>
                }
                
            </OverlayPanel>
        </>;
    }

    const getLocation = () => {
        if('geolocation' in navigator){
            navigator.geolocation.watchPosition(position => {
               //console.log(position.coords.latitude, position.coords.longitude);
               currentLocationCords.current = position.coords;
               const getData = async () => {
                    const data = await fetch(`https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${position.coords.latitude}&longitude=${position.coords.longitude}&localityLanguage=pt`);
                    let cords = null;
                    try{
                        cords = await data.json();
                    }catch(e){}
                    console.log(cords);
                    let local = null;

                    if(cords)
                        local = `${cords.locality}, ${cords.city} - ${cords.principalSubdivision}`;

                    setCurrentLocation(local);
                }

               getData();

           });
   
       }
    }

    const getUserData = useCallback(()=> {

        const getData = async () => {
            const user = await getUserProfile(company.id);
            setUserData(user.data);
        
        }

        if(company)
            getData();

    }, [company]);

    useEffect(()=> {

        getLocation();

    },[]);

    useEffect(()=> {

        getUserData();

    },[getUserData])

    return( <div className="business-header">
         <Toolbar left={contentLeft} right={contentRight} />
            <Sidebar icons={()=>
                <h3 style={{width: '290px'}}>
                    Pedidos Web
                </h3>} visible={showMenu} onHide={() => setShowMenu(false)}>
                <Menu style={{width: '100%', border:'0'}} model={items} />
            </Sidebar>
    </div>)
})