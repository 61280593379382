import {URL_API} from '..';

const token = 'MzM1fHxhZG1pbjMzNXx8MTIzNA==';

export const getCompanyData = async (_id = null) =>{

    if(!_id){
        return null;
    }

    const header = new Headers();
    header.append('Authorization', `Bearer ${token}`);

    const params = {
        method: 'GET',
        headers: header,
        redirect: 'follow'
    };

   // const request = await fetch(`${URL_API}empresa/${_id}`, params);
   const request = await fetch(`${URL_API}companys/${_id}`, params);
    let json;

    try{
     json = await request.json();
    }catch(e){
        json = null;
    }

    return json;

}

export const getCategoryProducts = async (_id = null) => {

    if(!_id){
        return null;
    }

    const header = new Headers();
    header.append('Authorization', `Bearer ${token}`);

    const params = {
        method: 'GET',
        headers: header,
        redirect: 'follow'
    };

    //const request = await fetch(`${URL_API}produto-categoria?empresa=${_id}&terminal=S`, params);
    const request = await fetch(`${URL_API}companys/${_id}/categories`, params);

    let json;

    try{
        json = await request.json();

        if(json)
            json.data = json.data.sort((a, b) => (~~a.id - ~~b.id));

    }catch(e){
        json = null;
    }

    return json;
}

export const getProducts = async (_id = null) => {
    
    if(!_id){
        return null;
    }

    const header = new Headers();
    header.append('Authorization', `Bearer ${token}`);

    const params = {
        method: 'GET',
        headers: header,
        redirect: 'follow'
    };

    //const request = await fetch(`${URL_API}produto?empresa=${_id}&ativo=S`, params);
    const request = await fetch(`${URL_API}companys/${_id}/products`, params);

    let json;

    try{
     json = await request.json();
    }catch(e){
        json = null;
    }

    return json;
}