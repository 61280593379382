import React, {useState, useEffect, useRef} from 'react';

import { InputText } from 'primereact/inputtext';
import {Password} from 'primereact/password';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';

import { useForm, Controller } from 'react-hook-form';

import { Icon } from 'react-icons-kit';
import {shop} from 'react-icons-kit/ikons/shop'

import { URL_APLICATION } from '../../../services/api';

import { userLogin } from '../../../services/api/client';

import { getCart } from '../../../services/shoppingcart';

import { login } from '../../../services/auth';

import '../../../assets/style/page/users/login/index.scss';

export const PageUserLogin = (props) => {


    const [isLoad, setIsload] = useState(false);
    const [avatar, setAvatar] = useState(null);

    const message = useRef();
    const listpassword = useRef();

    const defaultValues = {
        username:'',
        password:''
    }

    const formFrame = useForm({ defaultValues });
    const control = formFrame.control;
    const errors = formFrame.formState;
    const handleSubmit = formFrame.handleSubmit;

    const getAvatar = () => {
        return avatar ? avatar : <Icon size={'4em'} icon={shop} /> ;
    }

    const executeLogin = async (_data) => {

        setIsload(true);


        const data = await userLogin(props.company.id, _data.username, listpassword.current, true);
        
        if(data.data){
            const cart = getCart();
            login(data.data);

            props.onUpdateRoot();

            if(cart.length > 0){
                props.history.push(`/${props.company.id}/cart`);
            }else{
                props.history.push(`/${props.company.id}`);
            }

            
        }else{
            message.current.show({severity: 'error', summary: 'Erro', detail: 'Usuário ou senha inválido'});
        }

        setIsload(false);

    }


    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };

    const addDigitKeyboard = (_data) => {
        //const temp = formFrame.getValues().password.split('');
        const temp = (listpassword.current || '').split('');
        temp.push(..._data);
        console.log('--', temp);
        listpassword.current = temp.join('');

        const inputpass = [...temp];
        inputpass.length = Math.floor(inputpass.length * 0.5);

        inputpass.fill(0);

        formFrame.setValue('password', inputpass.join(''));
    }

    const removeDigitKeyboard = () => {
       // const temp = formFrame.getValues().password.split('');
       const temp = (listpassword.current || '').split('');
        temp.length = Math.max(temp.length - 2, 0);
        listpassword.current = temp.join('');

        const inputpass = [...temp];
        inputpass.length = Math.floor(inputpass.length * 0.5);

        inputpass.fill(0);


        formFrame.setValue('password', inputpass.join(''));
    }

    const getKeyboard = () => {
        const numbers = '0123456789';
        let butonsValue = [];
        
        const list = numbers.split('').sort((a) => Math.random() - 0.5);

        for(let i=0, ii=list.length; i < ii; i+=2){
            butonsValue.push([~~list[i], ~~list[i +1]].sort());
        }

        console.log(butonsValue);

        return <div className="keyboard-password">
            {
                butonsValue.map(item =>  <Button onClick={(e)=>{
                    e.preventDefault();
                    addDigitKeyboard(item);
                    
                }} className="p-button-plain" label={`${item[0]} ou ${item[1]}`} /> )
            }
            <Button onClick={(e)=>{ e.preventDefault(); removeDigitKeyboard();}} className="p-button-plain" icon="pi pi-caret-left" />
        </div>

    }

    useEffect(() => {

        const avatar_image = new Image();
        avatar_image.setAttribute('src', `${URL_APLICATION}${props.company.detalhe.icone}`);
        avatar_image.addEventListener('load', ()=>{
            setAvatar(<img src={`${URL_APLICATION}${props.company.detalhe.icone}`} alt="" />);
        });

    },[props])

    return (
        <div className="page-user-login">
            <div className="main-frame">
                <div className="p-grid">
                    <div className="p-col">
                        <div className="avatar">
                            {getAvatar()}
                        </div>
                    </div>
                </div>
                <div className="p-grid">
                    <div className="p-col">
                        <div className="company-name">
                            <h2>{props.company.nome}</h2>
                        </div>
                    </div>
                </div>
                <form onSubmit={handleSubmit(executeLogin)}>
                    <div className="p-grid">
                        <div className="p-col">
                            <div className="p-field">
                                <div className="p-float-label">
                                        <Controller name="username" control={control} rules={{ required: 'Usuário obrigatório.' }} render={({ field, fieldState }) => (
                                            <InputText type="number" maxLength="100" id={field.name} {...field} className={{ 'p-invalid': fieldState.invalid }} />
                                        )} />
                                    <label htmlFor="username" className={{ 'p-error': !!errors.username }}>CPF *</label>
                                </div>
                                {getFormErrorMessage('username')}
                            </div>
                        </div>
                    </div>
                    <div className="p-grid p-mt-2">
                        <div className="p-col">
                            <div className="p-field">
                                <div className="p-float-label">
                                        <Controller name="password" control={control} rules={{ required: 'Senha obrigatória.' }} render={({ field, fieldState }) => (
                                            <>
                                                <Password disabled maxLength="100" feedback={false}  id={field.name} {...field} className={{ 'p-invalid': fieldState.invalid }} />
                                            </>
                                        )} />
                                    <label htmlFor="password" className={{ 'p-error': !!errors.password }}>Senha*</label>
                                </div>
                                {getFormErrorMessage('password')}
                                <div className="p-grid">
                                    <div className="p-col-12">
                                        {getKeyboard()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="remenber-password">
                        <span>Esqueceu sua senha?</span>
                    </div>
                    <div className="p-d-flex p-mt-2">
                        <div className="p-col area-button">
                            <Button loading={isLoad} type="submit" label="Entrar" />
                        </div>
                    </div>
                </form>
            </div>
            <Toast ref={message} position="bottom-center" />
        </div>
    )
}