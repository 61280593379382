import React, {useState, useRef, useEffect, useCallback} from 'react';

import { PageBusinessHeader } from '../../components/pages/business/header';

import { getCompanyData } from '../../services/api/company';

import { ProgressSpinner } from 'primereact/progressspinner';


import PagesRouter from '../../components/navigation/pages';


import '../../assets/style/page/business/index.scss';




export const PageBusiness = (props) => {

    const [company, setCompany] = useState(null);
    const [updatePageid, setUpdatePageId] = useState('');
    const header =  useRef();


    const updateRoot = () => {
        header.current?.updateHeader();
    }
    
    const getDataCompany = useCallback(() => {

        const getData = async () => {
            const data_company =  await getCompanyData(props.match.params.client);
            setCompany(data_company.data);
        }

        getData();


    }, [props]);

    useEffect(()=> {

        getDataCompany()


    },[getDataCompany]);

    useEffect(()=> {

        console.log('Alter page');
        header.current.closeMenu();
        setUpdatePageId(new Date().getTime());

    },[props]);

    return (
        <div className="page-business">
            <PageBusinessHeader ref={header} {...props} company={company} />
            <div className="content" id={`page-id-${updatePageid}`}>
                {
                company ?
                     <PagesRouter currentpage={`page-ref-${updatePageid}`} company={company} {...props} onUpdateRoot={updateRoot} />
                :
                    <div className="box-load">
                        <ProgressSpinner/>
                    </div>
                }
            </div>
        </div>
    )
}