import React, {useState, useEffect, useCallback} from 'react';

import { ProgressBar } from 'primereact/progressbar';
import { Button } from 'primereact/button';

import { CardAddress } from '../../../components/card/address';

import { getUserAddress } from '../../../services/api/client';

import '../../../assets/style/page/users/address/index.scss';

export const PageUserAddress = (props) => {

    const [list, setList] = useState(null);

    const onDelete = async (_id) => {

    }

    const onUpdate = async(_id) => {
        const item = list.filter(el => ~~el.id === ~~_id)[0];
        props.history.push({pathname:`/${props.company.id}/user/address/edit/${_id}`, state:{data:item}});
    }

    const getUserData = useCallback(() =>{

        const getData = async () => {
            const data = await getUserAddress(props.company?.id);
        
            setList(data?.data ? data.data : []);
        }

        getData();

    }, [props])

    useEffect(()=> {

        getUserData();

    }, [getUserData])

    return (
        <div className="page-user-address">
            <div className="main-frame">
                <h3>
                    Endereços do usuário
                </h3>
                <div className="p-flex p-mb-3">
                    <div className="p-col-12" style={{textAlign:'right'}}>
                        <Button onClick={()=>{ 
                            props.history.push(`/${props.company.id}/user/address/new`);
                        }} icon="pi pi-plus" className="p-mr-2 p-button-text" label="Adicionar novo endereço"  />
                    </div>
                </div>
                {
                    list ?
                        <div key={`address-list-area`}>
                            {
                                list.length > 0 ?
                                    <div key={`address-list`}>{list.map(item => <CardAddress key={`card-addres-${item.id}`} onUpdate={onUpdate} onDelete={onDelete} {...item} />)}</div>
                                :
                                    <p>
                                        Não há endereços cadastrados.
                                    </p>
                            }
                        </div>
                    :
                        <ProgressBar style={{width: '100%', minWidth: '100px', height:'10px'}} mode="indeterminate" />
                }
            </div>
        </div>
    )
}