import React, {useState, useRef} from 'react';

import { InputText } from 'primereact/inputtext';
import {Password} from 'primereact/password';
import { Divider } from 'primereact/divider';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { addLocale } from 'primereact/api';
import { Toast } from 'primereact/toast';

import { useForm, Controller } from 'react-hook-form';

import { localeData } from '../../../services/utils';

import { userCreate } from '../../../services/api/client';

import '../../../assets/style/page/users/sigup/index.scss';

export const PageUserSigup = (props) => {

    addLocale('br', localeData.br);

    const [addressData, setAddressData] = useState(null);
    const [isLoad, setIsLoad] = useState(false);

    const defaultValues = {
        nome: '',
        cpf:'',
        rg:'',
        sexo:'',
        nascimento:'',
        telefone:'',
        celular: '',
        cep: '',
        endereco:'',
        numero:'',
        comple:'',
        bairro:'',
        cidade:'',
        uf:'',
        email:'',
        senha:''
    }

    //const { control, formState: { errors }, handleSubmit } = useForm({ defaultValues });
    const formFrame = useForm({ defaultValues });
    const control = formFrame.control;
    const errors = formFrame.formState;
    const handleSubmit = formFrame.handleSubmit;
    const reset = formFrame.reset;

    const message = useRef();

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };

    const getAddressData = async (_cep = null) => {

        if(_cep){

            if(_cep.replace(/\D/gi, '').length === 8){

                setAddressData(null);

                const data = await fetch(`https://viacep.com.br/ws/${_cep.replace(/\D/gi, '')}/json/`);
                const address = await data.json();

                setAddressData(address);

                formFrame.setValue('endereco', address.logradouro);
                formFrame.setValue('bairro', address.bairro);
                formFrame.setValue('cidade', address.localidade);
                formFrame.setValue('uf', address.uf);
            }
        }


    }

    const onSave = async (_data) => {
        console.log(_data);
        setIsLoad(true);
        const userID = await userCreate(_data);
        setIsLoad(false);

        if(userID){
            message.current.show({severity: 'success', summary: 'Sucesso', detail: 'Usuário criado com sucesso'});
            reset();
        }

    }

    return (
        <div className="page-user-sigup">
            <div className="main-frame">
                <h3>
                    Cadastro de usuário
                </h3>
                <form onSubmit={handleSubmit(onSave)} >
                    <Divider className="p-mb-5" align="left">
                        <div className="p-d-inline-flex p-ai-center">
                            <i style={{fontSize:'1.5em'}} className="pi pi-user p-mr-2"></i>
                            <b style={{fontSize:'1.5em'}}>Dados</b>
                        </div>
                    </Divider>
                    <div className="p-grid p-mt-2">
                        <div className="p-col-12 p-md-6">
                            <div className="p-field">
                                <div className="p-float-label">
                                        <Controller name="nome" control={control} rules={{ required: 'Campo obrigatório.', maxLength: 100 }} render={({ field, fieldState }) => (
                                            <InputText id={field.name} {...field} className={{ 'p-invalid': fieldState.invalid }} />
                                        )} />
                                    <label htmlFor="username" className={{ 'p-error': !!errors.nome }}>Nome *</label>
                                </div>
                                {getFormErrorMessage('username')}
                            </div>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <div className="p-field">
                                <div className="p-float-label">
                                        <Controller name="email" control={control}  render={({ field, fieldState }) => (
                                            <InputText type="email"ext pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" maxLength="100" id={field.name} {...field} className={{ 'p-invalid': fieldState.invalid }} />
                                        )} />
                                    <label htmlFor="email" className={{ 'p-error': !!errors.nome }}>Email</label>
                                </div>
                                {getFormErrorMessage('email')}
                            </div>
                        </div>
                    </div>
                    <div className="p-grid p-mt-2">
                        <div className="p-col-12 p-md-6">
                            <div className="p-field">
                                <div className="p-float-label">
                                        <Controller name="rg" control={control} rules={{ maxLength: 20 }} render={({ field, fieldState }) => (
                                            <InputText id={field.name} {...field} className={{ 'p-invalid': fieldState.invalid }} />
                                        )} />
                                    <label htmlFor="rg" className={{ 'p-error': !!errors.rg }}>RG</label>
                                </div>
                                {getFormErrorMessage('rg')}
                            </div>
                        </div>
                        <div className="p-col-12 p-md-3">
                            <div className="p-field">
                                <div className="p-float-label">
                                        <Controller name="sexo" control={control}  render={({ field, fieldState }) => (
                                            <Dropdown options={[{label:'Masculino', value:'M'}, {label:'Feminino', value:'F'}]} id={field.name} {...field} className={{ 'p-invalid': fieldState.invalid }}/>
                                        )} />
                                    <label htmlFor="sexo" className={{ 'p-error': !!errors.email }}>Sexo</label>
                                </div>
                                {getFormErrorMessage('sexo')}
                            </div>
                        </div>
                        <div className="p-col-12 p-md-3">
                            <div className="p-field">
                                <div className="p-float-label">
                                        <Controller name="nascimento" control={control}  render={({ field, fieldState }) => (
                                            <Calendar monthNavigator yearNavigator yearRange={`1900:${new Date().getFullYear()}`} monthNavigatorTemplate={(e)=>{
                                                return <Dropdown value={e.value} options={e.options} onChange={(event) => e.onChange(event.originalEvent, event.value)} style={{ lineHeight: 1 }} />;
                                            }} 
                                                yearNavigatorTemplate={(e)=>{
                                                    return <Dropdown value={e.value} options={e.options} onChange={(event) => e.onChange(event.originalEvent, event.value)} className="p-ml-2" style={{ lineHeight: 1 }} />;
                                                }} dateFormat="dd/mm/yy" locale="br" id={field.name} {...field} className={{ 'p-invalid': fieldState.invalid }}></Calendar>
                                        )} />
                                    <label htmlFor="nascimento" className={{ 'p-error': !!errors.email }}>Data Nascimento</label>
                                </div>
                                {getFormErrorMessage('nascimento')}
                            </div>
                        </div>
                    </div>
                    <div className="p-grid p-mt-2">
                        <div className="p-col-12 p-md-6">
                            <div className="p-field">
                                <div className="p-float-label">
                                        <Controller name="telefone" control={control} rules={{  maxLength: 15 }} render={({ field, fieldState }) => (
                                            <InputText type="tel" mask="(99) 9999-9999" id={field.name} {...field} className={{ 'p-invalid': fieldState.invalid }} />
                                        )} />
                                    <label htmlFor="telefone" className={{ 'p-error': !!errors.telefone }}>Telefone</label>
                                </div>
                                {getFormErrorMessage('telefone')}
                            </div>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <div className="p-field">
                                <div className="p-float-label">
                                        <Controller name="celular" control={control} rules={{  maxLength: 15 }} render={({ field, fieldState }) => (
                                            <InputText type="tel" mask="(99) 9999-99999" id={field.name} {...field} className={{ 'p-invalid': fieldState.invalid }} />
                                        )} />
                                    <label htmlFor="celular" className={{ 'p-error': !!errors.celular }}>Celular</label>
                                </div>
                                {getFormErrorMessage('celular')}
                            </div>
                        </div>
                    </div>
                    <Divider className="p-mb-5" align="left">
                        <div className="p-d-inline-flex p-ai-center">
                            <i style={{fontSize:'1.5em'}} className="pi pi-map-marker p-mr-2"></i>
                            <b style={{fontSize:'1.5em'}}>Endereço</b>
                        </div>
                    </Divider>
                    <div className="p-grid p-mt-2">
                        <div className="p-col-12 p-md-6">
                            <div className="p-field">
                                <div className="p-float-label p-input-icon-right " style={{width:'100%'}}>
                                        <Controller name="cep" control={control}  rules={{  maxLength: 10 }} render={({ field, fieldState }) => (
                                                <>
                                                   { field.value.replace(/\D/gi,'').length === 8 && !addressData ? <i className="pi pi-spin pi-spinner" /> : '' }
                                                    <InputText type="number" mask="99.999.999"  maxLength="100" id={field.name} {...field} className={{ 'p-invalid': fieldState.invalid }} onChange = {(e)=>{ field.onChange(e.target.value); getAddressData(e.target.value)}}  />
                                                </>

                                        )} />
                                    <label htmlFor="cep" className={{ 'p-error': !!errors.cep }}>CEP</label>
                                </div>
                                {getFormErrorMessage('cep')}
                            </div>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <div className="p-field">
                                <div className="p-float-label">
                                        <Controller name="endereco" control={control}  render={({ field, fieldState }) => (
                                            <InputText disabled={!addressData} maxLength="100" id={field.name} {...field} className={{ 'p-invalid': fieldState.invalid }} />
                                        )} />
                                    <label htmlFor="endereco" className={{ 'p-error': !!errors.cpf }}>Endereço</label>
                                </div>
                                {getFormErrorMessage('endereco')}
                            </div>
                        </div>
                    </div>
                    <div className="p-grid p-mt-2">
                        <div className="p-col-12 p-md-3">
                            <div className="p-field">
                                <div className="p-float-label">
                                        <Controller name="numero" control={control} rules={{  maxLength: 50 }} render={({ field, fieldState }) => (
                                            <InputText id={field.name} {...field} className={{ 'p-invalid': fieldState.invalid }} />
                                        )} />
                                    <label htmlFor="numero" className={{ 'p-error': !!errors.numero }}>Número</label>
                                </div>
                                {getFormErrorMessage('numero')}
                            </div>
                        </div>
                        <div className="p-col-12 p-md-3">
                            <div className="p-field">
                                <div className="p-float-label">
                                        <Controller name="comple" control={control} rules={{ maxLength: 50 }} render={({ field, fieldState }) => (
                                            <InputText id={field.name} {...field} className={{ 'p-invalid': fieldState.invalid }} />
                                        )} />
                                    <label htmlFor="comple" className={{ 'p-error': !!errors.comple }}>Complemento</label>
                                </div>
                                {getFormErrorMessage('comple')}
                            </div>
                        </div>
                        <div className="p-col-12 p-md-2">
                            <div className="p-field">
                                <div className="p-float-label">
                                        <Controller name="bairro" control={control}  render={({ field, fieldState }) => (
                                            <InputText disabled={!addressData} maxLength="100" id={field.name} {...field} className={{ 'p-invalid': fieldState.invalid }} />
                                        )} />
                                    <label htmlFor="bairro" className={{ 'p-error': !!errors.bairro }}>Bairro</label>
                                </div>
                                {getFormErrorMessage('bairro')}
                            </div>
                        </div>
                        <div className="p-col-12 p-md-2">
                            <div className="p-field">
                                <div className="p-float-label">
                                        <Controller name="cidade" control={control}  render={({ field, fieldState }) => (
                                            <InputText disabled={!addressData} maxLength="100" id={field.name} {...field} className={{ 'p-invalid': fieldState.invalid }} />
                                        )} />
                                    <label htmlFor="cidade" className={{ 'p-error': !!errors.cidade }}>Cidade</label>
                                </div>
                                {getFormErrorMessage('cidade')}
                            </div>
                        </div>
                        <div className="p-col-12 p-md-2">
                            <div className="p-field">
                                <div className="p-float-label">
                                        <Controller name="uf" control={control}  render={({ field, fieldState }) => (
                                            <InputText disabled={!addressData} maxLength="100" id={field.name} {...field} className={{ 'p-invalid': fieldState.invalid }} />
                                        )} />
                                    <label htmlFor="uf" className={{ 'p-error': !!errors.uf }}>Estado</label>
                                </div>
                                {getFormErrorMessage('uf')}
                            </div>
                        </div>
                    </div>
                    <Divider className="p-mb-5" align="left">
                        <div className="p-d-inline-flex p-ai-center">
                            <i style={{fontSize:'1.5em'}} className="pi pi-shield p-mr-2"></i>
                            <b style={{fontSize:'1.5em'}}>Acesso</b>
                        </div>
                    </Divider>
                    <div className="p-grid p-mt-2">
                        <div className="p-col-12 p-md-6">
                            <div className="p-field">
                                <div className="p-float-label">
                                        <Controller name="cpf" control={control} rules={{ required: 'Campo obrigatório.', maxLength: 14, type:'number' }} render={({ field, fieldState }) => (
                                            <InputText type="number"  mask="999.999.999-99" pattern="[0-9]*" id={field.name} {...field} className={{ 'p-invalid': fieldState.invalid }} />
                                        )} />
                                    <label htmlFor="cpf" className={{ 'p-error': !!errors.cpf }}>CPF *</label>
                                </div>
                                {getFormErrorMessage('cpf')}
                            </div>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <div className="p-field">
                                <div className="p-float-label">
                                        <Controller name="senha" control={control} rules={{ required: 'Campo obrigatório.' }} render={({ field, fieldState }) => (
                                            <Password feedback={false} pattern="[0-9]*" toggleMask id={field.name} {...field} className={{ 'p-invalid': fieldState.invalid }} />
                                        )} />
                                    <label htmlFor="senha" className={{ 'p-error': !!errors.senha }}>Senha *</label>
                                </div>
                                {getFormErrorMessage('senha')}
                            </div>
                        </div>
                    </div>

                    <div className="p-grid p-mt-1">
                       <div className="p-col-12 area-button">
                           <Button loading={isLoad} type="submit" label="Cadastrar" />
                       </div>
                    </div>
                </form>
            </div>
            <Toast ref={message} position="bottom-center" />
        </div>
    )
}