import React, {useState, useEffect} from 'react';

import { Icon } from 'react-icons-kit';
import {ic_restaurant_menu} from 'react-icons-kit/md/ic_restaurant_menu';

import { URL_APLICATION } from '../../../services/api';

export const ProductImage = (props) => {

    let [image, setImage] = useState(<Icon size={'4em'} icon={ic_restaurant_menu} />);

    useEffect(()=> {

        if(props.url){
            const avatar_image = new Image();
            avatar_image.setAttribute('src', `${URL_APLICATION}${props.url}`);
            avatar_image.addEventListener('load', ()=>{
                setImage(<img style={{width:'100%', display:'block'}} src={`${URL_APLICATION}${props.url}`} alt="" />);
            });
            
            avatar_image.addEventListener('error', ()=>{
                setImage(<img style={{width:'100%', display:'block'}} src={`${URL_APLICATION}${props.alt}`} alt="" />);
            });
        }

    },[props]);

    return (
        <div className="product-image">
            {image}
        </div>
    )

}