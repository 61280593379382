import React, {useState} from 'react';

import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from 'primereact/dialog';

import { getCompanyData } from '../../services/api/company';

import '../../assets/style/page/home/index.scss';

export const PageHome = (props) => {

    const [company, setCompany] = useState('');
    const [isLoading, setIsloading] = useState(false);
    const [showDialog, setShowdialog] = useState(false);

    const getCompany = async () => {
        setIsloading(true);
        const data = await getCompanyData(company);

        if(data){
            props.history.push(`/${company}`);
        }else{
            setShowdialog(true);
        }
        setIsloading(false);


    }

    return (
        <div className="page-home">
            <div className="main-content">
                
                <form  onSubmit={(e)=>{ e.preventDefault(); }}>
                    <h3 style={{textAlign:'center'}}>
                        Pedidos Web
                    </h3>
                    <div className="p-grid">
                        <div className="p-col-12">
                            <div className="p-inputgroup">
                                <InputText value={company} onChange={(e)=> setCompany(e.target.value) } placeholder="Informe o código da empresa"/>
                                <Button loading={isLoading} onClick={()=> getCompany()} icon="pi pi-search" className="p-button-warning"/>
                            </div>
                        </div>
                    </div>
                    <Dialog header="Atenção" visible={showDialog} style={{ width: '50vw' }} footer={null} onHide={() => setShowdialog(false)}>
                        <p>Empresa não encontrada</p>
                    </Dialog>
                </form>
            </div>
        </div>
    )
}