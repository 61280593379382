import React, {useState, useEffect, useCallback, useRef} from 'react';

import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { AutoComplete } from 'primereact/autocomplete';
import { Toast } from 'primereact/toast';

import { useForm, Controller } from 'react-hook-form';

//import { getUserAddress } from '../../../../services/api/client';

import { userCreateCard } from '../../../../services/api/client';

import { localeData, getCardFlag, cardList  } from '../../../../services/utils';

import '../../../../assets/style/page/users/cards/index.scss';

export const PageUserCardCreate = (props) => {

    

    const [isLoad, setIsLoad] = useState(false);

    const monthNamesmonthNames = localeData.br.monthNames.map((item, id) => ({label: item, value: id + 1}));

    const years = [];

    const currentYear = new Date().getFullYear();

    for(let i = currentYear; i < currentYear + 10; i++){
        years.push({label: i, value: i});
    }

    const defaultValues = {
        bandeira:'',
        numerotruncado: '',
        portador:'',
        val_mes:'',
        val_ano:'',
        token:''
    }

    const formFrame = useForm({ defaultValues });
    const control = formFrame.control;
    const errors = formFrame.formState;
    const handleSubmit = formFrame.handleSubmit;
    const reset = formFrame.reset;

    const message = useRef();
    

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };

    const getCard = (_data) => {
        const card = getCardFlag(_data);
        console.log(card);
        if(card.length > 0)
            formFrame.setValue('bandeira', card[0]);
        else
            formFrame.setValue('bandeira', '');

    }


    const onSave = async (_data) => {
        console.log(_data);
        setIsLoad(true);

        const cardID = await userCreateCard(_data);

        setIsLoad(false);

        if(cardID){
            message.current.show({severity: 'success', summary: 'Sucesso', detail: 'Cartão criado com sucesso'});
            reset();
        }
        
    }

    const getUserData = useCallback(() =>{

        const getData = async () => {
            //const data = await getUserAddress(props.company?.id);
            //setList(data.data);
        }

        getData();

    }, [])

    useEffect(()=> {

        getUserData();

    }, [getUserData])

    return (
        <div className="page-user-cards">
            <div className="main-frame">
                <h3>
                    Adicionar cartão
                </h3>
                <form className="form-address" onSubmit={handleSubmit(onSave)} >
                    <div className="p-grid p-mt-2">
                        <div className="p-col-12 p-md-4">
                            <div className="p-field">
                                <div className="p-float-label p-input-icon-right " style={{width:'100%'}}>
                                        <Controller name="numerotruncado" control={control}  rules={{ required:'Campo obrigatório.' }} render={({ field, fieldState }) => (
                                               <InputText type="number" mask="99.999.999" onChange={getCard(field.value) }  maxLength="100" id={field.name} {...field} className={{ 'p-invalid': fieldState.invalid }}   />
                                        )} />
                                    <label htmlFor="numerotruncado" className={{ 'p-error': !!errors.numerotruncado }}>Número *</label>
                                </div>
                                {getFormErrorMessage('numerotruncado')}
                            </div>
                        </div>
                        <div className="p-col-12 p-md-2">
                            <div className="p-field">
                                <div className="p-float-label p-input-icon-right " style={{width:'100%'}}>
                                        <Controller name="bandeira" control={control}  rules={{ required:'Campo obrigatório.',  maxLength: 20 }} render={({ field, fieldState }) => (
                                                <AutoComplete value={field.value} suggestions={Object.keys(cardList)} completeMethod={(e)=>{
                                                    
                                                }}   maxLength="100" id={field.name} {...field} className={{ 'p-invalid': fieldState.invalid }} />

                                        )} />
                                    <label htmlFor="bandeira" className={{ 'p-error': !!errors.bandeira }}>Bandeira *</label>
                                </div>
                                {getFormErrorMessage('bandeira')}
                            </div>
                        </div>
                        
                        <div className="p-col-12 p-md-6">
                            <div className="p-field">
                                <div className="p-float-label">
                                        <Controller name="portador" control={control} rules={{ required: 'Campo obrigatório.', maxLength: 100 }} render={({ field, fieldState }) => (
                                            <InputText id={field.name} {...field} className={{ 'p-invalid': fieldState.invalid }} />
                                        )} />
                                    <label htmlFor="portador" className={{ 'p-error': !!errors.portador }}>Nome *</label>
                                </div>
                                {getFormErrorMessage('portador')}
                            </div>
                        </div>
                        
                    </div>

                        <div className="p-grid p-mt-2 p-ai-center">
                            
                            <div className="p-col-12 p-md-1">
                                <strong  style={{fontSize:'0.8em', marginBottom:'10px', display:'inline-block'}}>Validade</strong>
                            </div>
                            <div className="p-col-12 p-md-3">
                                <div className="p-field">
                                    <div className="p-float-label">
                                            <Controller name="val_mes" control={control} rules={{ required:'Campo obrigatório.'}}  render={({ field, fieldState }) => (
                                                <Dropdown value={field.value} options={monthNamesmonthNames} onChange={(e) => field.onChange(e.value)} id={field.name} {...field} className={{ 'p-invalid': fieldState.invalid }}/>
                                            )} />
                                        <label htmlFor="val_mes" className={{ 'p-error': !!errors.val_mes }}>Mês *</label>
                                    </div>
                                    {getFormErrorMessage('val_mes')}
                                </div>
                            </div>
                            <div className="p-col-12 p-md-2">
                                <div className="p-field">
                                    <div className="p-float-label">
                                            <Controller name="val_ano" control={control} rules={{ required:'Campo obrigatório.'}} render={({ field, fieldState }) => (
                                                <Dropdown value={field.value} options={years} onChange={(e) => field.onChange(e.value)} id={field.name} {...field} className={{ 'p-invalid': fieldState.invalid }}/>
                                            )} />
                                        <label htmlFor="val_ano" className={{ 'p-error': !!errors.val_ano }}>Ano *</label>
                                    </div>
                                    {getFormErrorMessage('val_ano')}
                                </div>
                            </div>
                            <div className="p-col-12 p-md-6 p-lg-6">
                                <div className="p-field">
                                    <div className="p-float-label">
                                            <Controller name="token" control={control} rules={{ maxLength: 50 }} render={({ field, fieldState }) => (
                                                <InputText id={field.name} {...field} className={{ 'p-invalid': fieldState.invalid }} />
                                            )} />
                                        <label htmlFor="token" className={{ 'p-error': !!errors.token }}>Digito verificador (CVV)</label>
                                    </div>
                                    {getFormErrorMessage('token')}
                                </div>
                            </div>
                        </div>

                        <div className="p-grid p-mt-2 p-mb-4 ">
                            <div className="p-col-12" style={{textAlign:'right'}}>
                                <Button onClick={()=> props.history.push(`/${props.company.id}/user/cards`)} style={{width:'100px'}} className="p-mr-2  p-button-danger" label="Cancelar"  />
                                <Button loading={isLoad} style={{width:'100px'}} type="submit" label="Cadastro" />
                            </div>
                        </div>       
                 </form>
            </div>
            <Toast ref={message} position="bottom-center" />
        </div>
    )
}