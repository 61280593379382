import React, {useState, useEffect, useCallback} from 'react';

import { ProgressBar } from 'primereact/progressbar';

import { CardRequest } from '../../../components/card/request';

import { getUserRequests } from '../../../services/api/client';

import '../../../assets/style/page/users/address/index.scss';

export const PageUserRequests = (props) => {

    const [list, setList] = useState(null);

    const onDelete = async (_id) => {

    }

    const onUpdate = async(_id) => {
        const item = list.filter(el => ~~el.id === ~~_id)[0];
        props.history.push({pathname:`/${props.company.id}/user/address/edit/${_id}`, state:{data:item}});
    }

    const getUserData = useCallback(() =>{

        const getData = async () => {
            const data = await getUserRequests(props.company?.id);
        
            setList(data?.data ? data.data : []);
        }

        getData();

    }, [props])

    useEffect(()=> {

        getUserData();

    }, [getUserData])

    return (
        <div className="page-user-address">
            <div className="main-frame">
                <h3>
                    Pedidos do usuário
                </h3>
                {
                    list ?
                        <div key={`address-list-area`}>
                            {
                                list.length > 0 ?
                                    <div key={`address-list`}>{list.map(item => <CardRequest key={`card-request-${item.id}`} onUpdate={onUpdate} onDelete={onDelete} {...item} />)}</div>
                                :
                                    <p>
                                        Não há pedidos cadastrados.
                                    </p>
                            }
                        </div>
                    :
                        <ProgressBar style={{width: '100%', minWidth: '100px', height:'10px'}} mode="indeterminate" />
                }
            </div>
        </div>
    )
}