import React from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';

import { PageHome } from '../../../pages/home';
import { PageBusiness } from '../../../pages/business';


const MainRouter = () => {
    console.log('Router Main');
    return(
        <BrowserRouter basename="/">
            <Switch>
                <Route exact path="/:client/:area?/:page?/:action?/:item?" render={(p)=> <PageBusiness  {...p} />} />
                <Route path="*" component={(p)=><PageHome  {...p}/> } />
            </Switch>
        </BrowserRouter>
    )
}

export default MainRouter;