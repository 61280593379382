import React from 'react';

import { Button } from 'primereact/button';

import { ProductImage } from '../image';

import '../../../assets/style/component/card/product/index.scss';

export const CardProduct = (props) => {

    //console.log(props);

    return(
        <div id={props.cardid} category={props.categoria} className="card-product p-shadow-1">
            <div className="main-frame">
                <div className="area-image">
                    <div className="avatar">
                        <ProductImage url={props?.icone_pedidoweb} alt={props?.icone_url} />
                    </div>
                </div>
                <div className="area-text">
                    <div className="product-name">
                        {props.produto}
                    </div>
                    <div className="product-description">
                        {props?.detalhes}
                    </div>
                    <div className="product-action">
                        <div className="price">
                            R$ {parseFloat(props.preco).toFixed(2).toString().replace('.',',')}
                        </div>
                        <div className="button">
                            <Button onClick={()=> props.onAddCart(props) } icon="pi pi-shopping-cart" className="p-buttom-sm" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}