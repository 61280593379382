import { URL_APLICATION } from "./services/api";

export const registerManifest = (_data = null) => {
    console.log('Register Manifest');

    if(_data){
        const json = {
            short_name: `${ _data.nome }`,
            name: `${_data.nome}`,
            description:`${_data.detalhe.slogan}`,
            start_url: `${window.location.origin}/${ _data.id}`,
            display: "standalone",
            theme_color: "#ffffff",
            background_color: "#ffffff",
            icons: [
                {
                    src: `${window.location.origin}/favicon.ico`,
                    sizes: "64x64 32x32 24x24 16x16",
                    type: "image/x-icon"
                },
                {
                    src: `${_data ? URL_APLICATION + _data.detalhe.icone : `${window.location.origin}/logo192.png`}`,
                    type: "image/png",
                    sizes: "192x192"
                },
                {
                    src: `${_data ? URL_APLICATION + _data.detalhe.icone : `${window.location.origin}/logo512.png`}`,
                    type: "image/png",
                    sizes: "512x512"
                }
            ]
        }
        
        
        const manifest = new Blob([JSON.stringify(json)], {type:'application/json'});
        const linkmanifest = document.querySelector('link[rel*="manifest"]');
        linkmanifest.setAttribute('href', window.URL.createObjectURL(manifest));
    }

    //console.log(window.URL.createObjectURL(manifest));

}

export const registerPWA = (_id='main') => {
    if ('serviceWorker' in navigator) {
        window.addEventListener('load', function() {
          navigator.serviceWorker.register(`service-worker.js?id=${_id}`).then(function(registration) {
            console.log('ServiceWorker registration successful with scope: ', registration.scope);
          }, function(err) {
            console.log('ServiceWorker registration failed: ', err);
          }).catch(function(err) {
            console.log(err)
          });
        });
      } else {
        console.log('service worker is not supported');
    }
}