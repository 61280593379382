import React, {useState, useEffect, useCallback, useMemo, useRef} from 'react';

import { InputText } from 'primereact/inputtext';
import {Password} from 'primereact/password';
import { Divider } from 'primereact/divider';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { addLocale } from 'primereact/api';
import { Toast } from 'primereact/toast';

import { useForm, Controller } from 'react-hook-form';

import { localeData } from '../../../services/utils';

import { getUserProfile, userUpdate } from '../../../services/api/client';

import '../../../assets/style/page/users/profile/index.scss';

export const PageUserProfile = (props) => {

    addLocale('br', localeData.br);

    const [isLoad, setIsLoad] = useState(false);
    

    const defaultValues = useMemo(()=> ({
        nome: '',
        cpf:'',
        rg:'',
        sexo:'',
        nascimento:'',
        telefone:'',
        celular: '',
        email:'',
        senha:''
    }),[])

    const message = useRef();
    const userData = useRef();

    const formFrame = useForm({ defaultValues });
    const control = formFrame.control;
    const errors = formFrame.formState;
    const handleSubmit = formFrame.handleSubmit;

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };

    const onSave = async (_data) => {
        
        setIsLoad(true);

        _data.id = userData.current.id;

        if(!_data.senha)
            delete _data.senha;

        console.log(_data);

        const userID = await userUpdate(_data);

        setIsLoad(false);

        if(userID){
            message.current.show({severity: 'success', summary: 'Sucesso', detail: 'Usuário atualizado com sucesso'});
        }

        getUserData();
        
    }

    const getUserData = useCallback(() =>{

        const getData = async () => {
            const data = await getUserProfile(props.company.id);
            userData.current = data.data;
            const keys = Object.keys(defaultValues);

            console.log(data.data);

            if(data.data){
                keys.map(item => {

                    if(!/senha/gi.test(item))
                        formFrame.setValue(item, data.data[item]);
                    
                    if(/(cpf|telefone|celular)/gi.test(item))
                        formFrame.setValue(item, data.data[item]?.toString()?.replace(/\D/gi,''));
                    
                    if(/(nascimento)/gi.test(item))
                        formFrame.setValue(item, new Date(data.data[item]));

                    return item;
                })
            }
        }

        getData();

    }, [defaultValues, formFrame, props])

    useEffect(()=> {

        getUserData();

    }, [getUserData])

    return (
        <div className="page-user-profile">
            <div className="main-frame">
                <h3>
                    Atualização do usuário
                </h3>
                <form onSubmit={handleSubmit(onSave)} >
                    <Divider className="p-mb-5" align="left">
                        <div className="p-d-inline-flex p-ai-center">
                            <i style={{fontSize:'1.5em'}} className="pi pi-user p-mr-2"></i>
                            <b style={{fontSize:'1.5em'}}>Dados</b>
                        </div>
                    </Divider>
                    <div className="p-grid p-mt-2">
                        <div className="p-col-12 p-md-6">
                            <div className="p-field">
                                <div className="p-float-label">
                                        <Controller name="nome" control={control} rules={{ required: 'Campo obrigatório.', maxLength: 100 }} render={({ field, fieldState }) => (
                                            <InputText id={field.name} {...field} className={{ 'p-invalid': fieldState.invalid }} />
                                        )} />
                                    <label htmlFor="username" className={{ 'p-error': !!errors.nome }}>Nome *</label>
                                </div>
                                {getFormErrorMessage('username')}
                            </div>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <div className="p-field">
                                <div className="p-float-label">
                                        <Controller name="email" control={control} rules={{ required: 'Campo obrigatório.' }} render={({ field, fieldState }) => (
                                            <InputText type="email" maxLength="100" id={field.name} {...field} className={{ 'p-invalid': fieldState.invalid }} />
                                        )} />
                                    <label htmlFor="email" className={{ 'p-error': !!errors.nome }}>Email *</label>
                                </div>
                                {getFormErrorMessage('email')}
                            </div>
                        </div>
                    </div>
                    <div className="p-grid p-mt-2">
                        <div className="p-col-12 p-md-6">
                            <div className="p-field">
                                <div className="p-float-label">
                                        <Controller name="rg" control={control} rules={{ maxLength: 20 }} render={({ field, fieldState }) => (
                                            <InputText id={field.name} {...field} className={{ 'p-invalid': fieldState.invalid }} />
                                        )} />
                                    <label htmlFor="rg" className={{ 'p-error': !!errors.rg }}>RG</label>
                                </div>
                                {getFormErrorMessage('rg')}
                            </div>
                        </div>
                        <div className="p-col-12 p-md-3">
                            <div className="p-field">
                                <div className="p-float-label">
                                        <Controller name="sexo" control={control}  render={({ field, fieldState }) => (
                                            <Dropdown options={[{label:'Masculino', value:'M'}, {label:'Feminino', value:'F'}]} id={field.name} {...field} className={{ 'p-invalid': fieldState.invalid }}/>
                                        )} />
                                    <label htmlFor="sexo" className={{ 'p-error': !!errors.sexo }}>Sexo</label>
                                </div>
                                {getFormErrorMessage('sexo')}
                            </div>
                        </div>
                        <div className="p-col-12 p-md-3">
                            <div className="p-field">
                                <div className="p-float-label">
                                        <Controller name="nascimento" control={control}  render={({ field, fieldState }) => (
                                            <Calendar monthNavigator yearNavigator yearRange={`1900:${new Date().getFullYear()}`} monthNavigatorTemplate={(e)=>{
                                                return <Dropdown value={e.value} options={e.options} onChange={(event) => e.onChange(event.originalEvent, event.value)} style={{ lineHeight: 1 }} />;
                                            }} 
                                                yearNavigatorTemplate={(e)=>{
                                                    return <Dropdown value={e.value} options={e.options} onChange={(event) => e.onChange(event.originalEvent, event.value)} className="p-ml-2" style={{ lineHeight: 1 }} />;
                                                }} dateFormat="dd/mm/yy" locale="br" id={field.name} {...field} className={{ 'p-invalid': fieldState.invalid }}></Calendar>
                                        )} />
                                    <label htmlFor="nascimento" className={{ 'p-error': !!errors.nascimento }}>Data Nascimento</label>
                                </div>
                                {getFormErrorMessage('nascimento')}
                            </div>
                        </div>
                    </div>
                    <div className="p-grid p-mt-2">
                        <div className="p-col-12 p-md-6">
                            <div className="p-field">
                                <div className="p-float-label">
                                        <Controller name="telefone" control={control} rules={{  maxLength: 15 }} render={({ field, fieldState }) => (
                                            <InputText type="tel" mask="(99) 9999-9999" id={field.name} {...field} className={{ 'p-invalid': fieldState.invalid }} />
                                        )} />
                                    <label htmlFor="telefone" className={{ 'p-error': !!errors.telefone }}>Telefone</label>
                                </div>
                                {getFormErrorMessage('telefone')}
                            </div>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <div className="p-field">
                                <div className="p-float-label">
                                        <Controller name="celular" control={control} rules={{  maxLength: 15 }} render={({ field, fieldState }) => (
                                            <InputText type="tel" mask="(99) 9999-99999" id={field.name} {...field} className={{ 'p-invalid': fieldState.invalid }} />
                                        )} />
                                    <label htmlFor="celular" className={{ 'p-error': !!errors.celular }}>Celular</label>
                                </div>
                                {getFormErrorMessage('celular')}
                            </div>
                        </div>
                    </div>
                    <Divider className="p-mb-5" align="left">
                        <div className="p-d-inline-flex p-ai-center">
                            <i style={{fontSize:'1.5em'}} className="pi pi-shield p-mr-2"></i>
                            <b style={{fontSize:'1.5em'}}>Acesso</b>
                        </div>
                    </Divider>
                    <div className="p-grid p-mt-2">
                        <div className="p-col-12 p-md-6">
                            <div className="p-field">
                                <div className="p-float-label">
                                        <Controller name="cpf" control={control} rules={{ required: 'Campo obrigatório.', maxLength: 14 }} render={({ field, fieldState }) => (
                                            <InputText type="number" pattern="[0-9]*" mask="999.999.999-99" id={field.name} {...field} className={{ 'p-invalid': fieldState.invalid }} />
                                        )} />
                                    <label htmlFor="cpf" className={{ 'p-error': !!errors.cpf }}>CPF *</label>
                                </div>
                                {getFormErrorMessage('cpf')}
                            </div>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <div className="p-field">
                                <div className="p-float-label">
                                        <Controller name="senha" control={control} render={({ field, fieldState }) => (
                                            <Password feedback={false} pattern="[0-9]*" toggleMask id={field.name} {...field} className={{ 'p-invalid': fieldState.invalid }} />
                                        )} />
                                    <label htmlFor="senha" className={{ 'p-error': !!errors.cpf }}>Senha *</label>
                                </div>
                                {getFormErrorMessage('senha')}
                            </div>
                        </div>
                    </div>
                    <div className="p-grid p-mt-2 p-mb-4">
                       <div className="p-col-12 area-button">
                           <Button loading={isLoad} type="submit" label="Atualizar" />
                       </div>
                    </div>
                </form>
            </div>
            <Toast ref={message} position="bottom-center" />
        </div>
    )
}