import {URL_API} from '..';
import { getToken } from '../../auth';

export const userLogin = async (_company, _user, _password, _criptokey = null) => {

    const user = {
        company: _company,
        username: _user,
        password:_password,
        criptokey: _criptokey
    }
    
    const header = new Headers();
    header.append('Content-Length',JSON.stringify(user).length);

    const params = {
        method: 'POST',
        headers: header,
        redirect: 'follow',
        body: JSON.stringify(user)
    };

   const request = await fetch(`${URL_API}users/${_company}/login/`, params);
    let json;

    try{
     json = await request.json();
    }catch(e){
        json = null;
    }

    return json;
}

export const getUserProfile = async (_id = null) =>{

    const token = getToken();

    if(!_id){
        return null;
    }

    const header = new Headers();
    header.append('Authorization', `Bearer ${token}`);

    const params = {
        method: 'GET',
        headers: header,
        redirect: 'follow'
    };

   const request = await fetch(`${URL_API}users/profile`, params);
    let json;

    try{
     json = await request.json();
    }catch(e){
        json = null;
    }

    return json;

}


export const getUserAddress = async (_id = null) =>{

    const token = getToken();

    if(!_id){
        return null;
    }

    const header = new Headers();
    header.append('Authorization', `Bearer ${token}`);

    const params = {
        method: 'GET',
        headers: header,
        redirect: 'follow'
    };

   const request = await fetch(`${URL_API}users/address`, params);
    let json;

    try{
     json = await request.json();
    }catch(e){
        json = null;
    }

    return json;

}

export const getUserCards = async (_id = null) =>{

    const token = getToken();

    if(!_id){
        return null;
    }

    const header = new Headers();
    header.append('Authorization', `Bearer ${token}`);

    const params = {
        method: 'GET',
        headers: header,
        redirect: 'follow'
    };

   const request = await fetch(`${URL_API}users/cards`, params);
    let json;

    try{
     json = await request.json();
    }catch(e){
        json = null;
    }

    return json;

}

export const getUserRequests = async (_id = null) =>{

    const token = getToken();

    if(!_id){
        return null;
    }

    const header = new Headers();
    header.append('Authorization', `Bearer ${token}`);

    const params = {
        method: 'GET',
        headers: header,
        redirect: 'follow'
    };

   const request = await fetch(`${URL_API}users/requests`, params);
    let json;

    try{
     json = await request.json();
    }catch(e){
        json = null;
    }

    return json;

}


export const userCreate = async (_data) => {

    
    const header = new Headers();
    header.append('Content-Length',JSON.stringify(_data).length);

    const params = {
        method: 'POST',
        headers: header,
        redirect: 'follow',
        body: JSON.stringify(_data)
    };

   const request = await fetch(`${URL_API}users/create`, params);
    let json;

    try{
     json = await request.json();
    }catch(e){
        json = null;
    }

    return json;
}

export const userUpdate = async (_data) => {

    const token = getToken();

    const header = new Headers();
    header.append('Authorization', `Bearer ${token}`);
    header.append('Content-Length',JSON.stringify(_data).length);

    const params = {
        method: 'PUT',
        headers: header,
        redirect: 'follow',
        body: JSON.stringify(_data)
    };

   const request = await fetch(`${URL_API}users/update`, params);
    let json;

    try{
     json = await request.json();
    }catch(e){
        json = null;
    }

    return json;
}


export const userCreateAddress = async (_data) => {

    const token = getToken();
    
    const header = new Headers();
    header.append('Authorization', `Bearer ${token}`);
    header.append('Content-Length',JSON.stringify(_data).length);

    const params = {
        method: 'POST',
        headers: header,
        redirect: 'follow',
        body: JSON.stringify(_data)
    };

   const request = await fetch(`${URL_API}users/create/address`, params);
    let json;

    try{
     json = await request.json();
    }catch(e){
        json = null;
    }

    return json;
}

export const userUpdateAddress = async (_data) => {

    const token = getToken();
    
    const header = new Headers();
    header.append('Authorization', `Bearer ${token}`);
    header.append('Content-Length',JSON.stringify(_data).length);

    const params = {
        method: 'PUT',
        headers: header,
        redirect: 'follow',
        body: JSON.stringify(_data)
    };

   const request = await fetch(`${URL_API}users/update/address`, params);
    let json;

    try{
     json = await request.json();
    }catch(e){
        json = null;
    }

    return json;
}


export const userCreateCard = async (_data) => {

    const token = getToken();
    
    const header = new Headers();
    header.append('Authorization', `Bearer ${token}`);
    header.append('Content-Length',JSON.stringify(_data).length);

    const params = {
        method: 'POST',
        headers: header,
        redirect: 'follow',
        body: JSON.stringify(_data)
    };

   const request = await fetch(`${URL_API}users/create/card`, params);
    let json;

    try{
     json = await request.json();
    }catch(e){
        json = null;
    }

    return json;
}

export const userUpdateCard = async (_data) => {

    const token = getToken();
    
    const header = new Headers();
    header.append('Authorization', `Bearer ${token}`);
    header.append('Content-Length',JSON.stringify(_data).length);

    const params = {
        method: 'PUT',
        headers: header,
        redirect: 'follow',
        body: JSON.stringify(_data)
    };

   const request = await fetch(`${URL_API}users/update/card`, params);
    let json;

    try{
     json = await request.json();
    }catch(e){
        json = null;
    }

    return json;
}