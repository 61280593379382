import React from 'react';

import { Button } from 'primereact/button';

import '../../../assets/style/component/card/address/index.scss';

export const CardAddress = (props) => {


    return (
        <div className="card-address">
            <div className="title">
                {props.titulo}
            </div>
            <div className="address">
                <div>
                    {props.endereco}, {props.numero}{props.complemento ? ` - ${props.complemento}` : ''} - {props.bairro} - {props.cidade}
                </div>
                <div>
                    {props.referencia ? `${props.referencia}` : ''}
                </div>
            </div>
            <div className="buttons">
                <Button onClick={()=> props.onUpdate(props.id)} icon="pi pi-pencil" className="p-mr-2 p-button-text"  />
                <Button onClick={()=> props.onDelete(props.id)} icon="pi pi-trash"  className="p-button-text p-button-danger" />
            </div>
        </div>
    )
}